$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

@mixin sm {
  @media screen AND (min-width: $breakpoint-sm) {
    @content;
  }
}
@mixin md {
  @media screen AND (min-width: $breakpoint-md) {
    @content;
  }
}
@mixin lg {
  @media screen AND (min-width: $breakpoint-lg) {
    @content;
  }
}
@mixin xl {
  @media screen AND (min-width: $breakpoint-xl) {
    @content;
  }
}
@mixin xxl {
  @media screen AND (min-width: $breakpoint-xxl) {
    @content;
  }
}

// Text and background colors
@mixin color-styles($name, $color) {
  .bg-#{$name} {
    background-color: $color;
  }

  .text-#{$name} {
    color: $color;
  }
}