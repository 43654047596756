* {
  margin: 0;
  padding: 0;
}

.nav-tabs {
  /* --bs-nav-tabs-border-width: 2px; */
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: transparent transparent #014779 !important;
  border-bottom: var(--bs-nav-tabs-border-width) solid
    var(--bs-nav-tabs-border-color);
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: #79808a !important;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-tabs .nav-link.active {
  color: #424242 !important;
  background-color: transparent !important;
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.prog-grid {
  background-color: #f4f4f4;
}

.prog-grid-1 {
  padding: 0 20px;
}

.prog-grid-2 h4 {
  padding-top: 30px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  margin: 0;
  color: #121212;
}

.prog-grid-2 p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  margin: 0;
  color: #5a5a5c;
}

.prog-notification {
  position: relative;
  left: 260px;
  top: 100px;
}

.prog-notification p {
  display: inline-block;
  background: #d1493d;
  color: #fff;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  padding: 5px 10px;
  border-radius: 12px;
}

/*--------------------------------------Grid 3---------------------------------------*/
.prog-grid-3 {
  margin-top: 20px;

  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  width: "100%";
  min-height: 195px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.prog-grid-3 .imgBox {
  width: 100%;
  /* height: 170px; */
  aspect-ratio: 16/9;
  position: relative;
  text-align: center;
}

.prog-grid-3 .imgBox .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  /* aspect-ratio: 1/1; */
}

.prog-card-body {
  padding: 10px 20px;
  min-height: 165px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.prog-grid-3 h5 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #344055;
  margin: 0;
}

.prog-grid-3 p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  display: flex;
  align-items: flex-end;
  color: #79808a;
  margin: 0;
}

.prog-grid-3-1 {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.prog-grid-3-1 h5 {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #79808a;
  margin: 0;
}

.arrow-icon {
  margin-left: 20px;
  background-color: #529c00;
  display: inline-block;
  padding: 10px;
  border-radius: 100px;
}

.progress-complete {
  background: #ffffff;
  opacity: 0.6;
  border: 1px solid #e2e8f0;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
}

.prog-nav-search-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.prog-nav-search-box .organization-search-box {
  /* border: 1px solid black; */
  width: 100%;
  margin-bottom: 10px;
}
.prog-nav-search-box .organization-search-box .form-control {
  /* border: 1px solid black; */
}
.prog-nav-search-box .organization-search-box .input-group-text {
  /* border: 1px solid black; */
  margin-left: 10px !important;
  border-radius: 6px !important;
}
.prog-nav-search-box .organization-search-box .input-group-text .search-icon {
  color: #121212;
}

.prog-nav-search-box nav .nav .nav-link {
  border: none;
  color: #014779 !important;
}
.prog-nav-search-box nav .nav .nav-link:hover {
  border-bottom: 2px solid #014779;
  color: #014779 !important;
}
.prog-nav-search-box nav .nav .nav-link.active {
  border-bottom: 2px solid #0a7fd3;
  color: #0a7fd3 !important;
}
.my-prog-card .no-data-found {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.my-prog-card .no-data-found img {
  height: 300px;
  width: 600px;
}

.prog-grid-3 .prog-card-body .title2{
  font-size: 12px;
  color: #222;
}

.org-name-badge {
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 999;
  /* box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.334); */
  background-color: #014779;
  color: #fff;
  padding: 5px 10px;
  font-size: 10px;
  border-radius: 2px;
  line-height: 1;
  font-family: 'Inter';
  font-weight: 500;
  text-transform: uppercase;
}

@media only screen and (min-width: 820px) and (max-width: 1070px) {
  /* .my-prog-card .col {
    flex: 0 0 50%;
    max-width: 0 0 50%;
  } */
}
/* .wizard-nav {
  padding-left: 40px;
  padding-right: 40px;
} */

@media only screen and (max-width: 920px) {
  .prog-nav-search-box {
    flex-direction: column-reverse;
  }
  .prog-grid-2 h4 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 820px) {
  .my-prog-card .col {
    flex: 0 0 100%;
    max-width: 0 0 100%;
  }
  .prog-grid-2 h4 {
    font-size: 28px;
  }
}
@media only screen and (max-width: 720px) {
  .prog-grid-2 h4 {
    font-size: 26px;
  }
}
