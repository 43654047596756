.organization-1{
    margin-top: 10px;
}

a {
    color: var(--bs-link-color);
    text-decoration: none;
}

.organization-grid-2{
    margin-top: 5px;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 46px;
    background: #FFFFFF;
    border: 1px solid #E2E8F0;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.organization-grid-2 h6{
    padding-top: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #121212;
}

.organization-arrow-icon{
    background-color: #529C00;
    display: inline-block;
    padding: 5px 6px;
    border-radius: 100px;
}