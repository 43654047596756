* {
  padding: 0;
  margin: 0;
}

.setting {
  background-color: #f4f4f4;
}
.setting-grid-1 {
  margin-top: 20px;
}
.setting-grid-1 h4 {
  /* padding-top: 20px; */
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  margin: 0;
  color: #121212;
}

.setting-grid-1 p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  color: #79808a;
}

/*---------------------------------setting grid 2----------------------------------*/
.setting-1 {
  margin-top: 6px;
}

.setting-grid-2 {
  margin-top: 5px;
  padding: 10px;
  box-sizing: border-box;
  /* width: 451px; */
  height: 46px;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.setting-grid-2.active {
  box-shadow: 0 0 2px #0a7fd3cc;
}
.setting-grid-2 h6 {
  padding-top: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #121212;
}

.setting-arrow-icon {
  background-color: #529c00;
  display: inline-block;
  padding: 5px 6px;
  border-radius: 100px;
}

/*------------------------------------------setting grid 3-------------------------------------------*/
.setting-grid-3 {
  margin: 20px;
}
.setting-grid-3-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.setting-grid-3-1 h6 {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #121212;
  margin: 0;
}

.setting-grid-3-2,
.setting-grid-3-3 {
  /* border: 1px solid #529c00; */
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.setting-grid-3-2 .form-control,
.setting-grid-3-3 .form-control {
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  width: 90%;
  padding: 8px;
  background: #ffffff;
}

.setting-grid-3-2 h6,
.setting-grid-3-3 h6 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #121212;
}

.mb-3 {
 margin: 0;
}

.form-text {
  padding-left: 10px !important;
}

.setting-grid-3-3 .form-control {
  /* width: 50%; */
}
.setting-grid-3-3 .setting-grid-profile-pic {
  border: 1px solid #e2e8f0;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.setting-grid-3-3 .setting-grid-profile-pic .setting-profile-pic {
  border-radius: 50px;
  height: 50px;
  width: 50px;
}
.setting-form {
  width: 210% !important;
}

.setting-grid-3-4 {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.setting-grid-3-4 h6 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #121212;
}

.setting-grid-3-5 {
  padding: 12px 10px;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;
}

.setting-grid-3-5 h5 {
  margin: 0;
  padding-left: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #121212;
}

.setting-grid-3-6 {
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.setting-grid-3-6 h6 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #121212;
}
.setting-grid-3-78 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.setting-grid-3-7 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.setting-grid-3-7 h5 {
  padding-top: 7px;
  padding-left: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #121212;
}

.setting-grid-3-8 {
  display: flex;
  align-items: center;
  /* padding-left: 142px; */
}

.setting-grid-3-8 h5 {
  padding-top: 7px;
  padding-left: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #121212;
}

.setting-grid-3-9 {
  margin-top: 40px;
  margin-left: 25%;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;
}

.setting-grid-3-9 .lock,
.setting-grid-3-10 .lock {
  padding: 6px;
  font-size: 10px;
  border-radius: 50%;
  background-color: #529c00;
  color: #fff;
}

.setting-grid-3-9 h5 {
  margin: 0;
  padding-left: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #121212;
}

.setting-grid-3-10 {
  margin-top: 18px;
  margin-left: 25%;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;
}

.setting-grid-3-10 h5 {
  margin: 0;
  padding-left: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #121212;
}

.my-profile-form button[type=submit] {
  /* font-size: 14px; */
  font-weight: 600;
  color: #529c00;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
  padding: 10px;
  min-width: 20%;
}




/* .badges-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
} */

.label {
  font-family: inter;
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}


/* .badges-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.badge-item {
  display: flex;
  align-items: center;
  min-width: 100px;
  text-align: center;
  margin-right: 10px;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
}

.badge-icon {
  height: 20px;
  width: 20px;
  margin: 5px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 0 0 3px white, 0 0 0 4px #014779;
}

.badge-title {
  font-size: 12px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  visibility: hidden;
  opacity: 0; 
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #014779;
  color: #fff; 
  padding: 5px 10px; 
  border-radius: 5px;
  pointer-events: none; 
}


.badge-item:hover .badge-title {
  visibility: visible;
  opacity: 1; 
}

.see-more-less {
  text-align: start;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #014779;
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .badges-list {
    justify-content: center;
  }

  .badge-item {
    margin: 5px;
  }
}
 */



@media only screen and (max-width: 1130px) {
  .setting-row .col {
    flex: 0 0 100%;
    max-width: 0 0 100%;
  }
  .setting-row .col .setting-1 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 650px) {
  .setting-grid-3 {
    margin: 0;
  }
}

@media only screen and (max-width: 810px) {
  .setting-grid-3-2 .label {
    flex: 0 0 34%;
    max-width: 0 0 34%;
  }
  .setting-grid-3-2 .input {
    flex: 0 0 33%;
    max-width: 0 0 33%;
  }
  .setting-grid-3-3 .label {
    flex: 0 0 34%;
    max-width: 0 0 34%;
  }
  .setting-grid-3-3 .input {
    flex: 0 0 66%;
    max-width: 0 0 66%;
  }
  .setting-grid-3-4 .label {
    flex: 0 0 34%;
    max-width: 0 0 34%;
  }
  .setting-grid-3-4 .setting-grid-3-5 {
    flex: 0 0 40%;
    max-width: 0 0 40%;
  }
  .setting-grid-3-6 .label {
    flex: 0 0 34%;
    max-width: 0 0 34%;
  }
  .setting-grid-3-6 .setting-grid-3-78 {
    flex: 0 0 66%;
    max-width: 0 0 66%;
  }
  .setting-grid-3-9 {
    margin-left: 34%;
  }
  .setting-grid-3-10 {
    margin-left: 34%;
  }
}


.delete-account-btn {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  background: #f33f3f;
  color: #fff;
  border: 1px solid #f33f3f;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
}

.delete-account-btn .icon {
  padding: 6px;
  font-size: 10px;
  border-radius: 50%;
  background-color: #fff;
  color: #f33f3f;
  margin-right: 10px;
}