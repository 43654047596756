.add-program2-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #014779;
  padding: 20px 40px;
}

.add-program2-header h5 {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #fff;
}

/*------------------------------------------grid 1--------------------------------------*/
.add-program2-grid {
  background: #f9f9f9;
  padding: 20px 20px;
}
.add-program2-grid-1 {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.add-program2-grid-1 .add-program2-grid-1-1 h5 {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  margin: 0;
  color: #121212;
}

.add-program2-grid-1 .add-program2-grid-1-1 p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  font-size: 15px;
  color: #424242;
}

.add-program2-form {
  /* padding: 0 10px !important; */
  width: 100% !important;
  background: #f8f9fa !important;
  border-radius: 8px !important;
}

.add-program2-grid-2-1,
.add-program2-grid-2-2,
.add-program2-grid-2-3,
.add-program2-grid-2-4,
.add-program2-grid-2-5,
.add-program2-grid-2-6,
.add-program2-grid-2-7,
.add-program2-grid-2-8,
.add-program2-grid-2-9,
.add-program2-grid-2-10 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;
}
.add-program2-grid-2-1 h6,
.add-program2-grid-2-2 h6,
.add-program2-grid-2-3 h6,
.add-program2-grid-2-4 h6,
.add-program2-grid-2-5 h6,
.add-program2-grid-2-6 h6,
.add-program2-grid-2-7 h6,
.add-program2-grid-2-8 h6,
.add-program2-grid-2-9 h6,
.add-program2-grid-2-10 h6 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #121212;
  width: 160px;
}
.add-program2-grid-2-1 p,
.add-program2-grid-2-2 p,
.add-program2-grid-2-3 p,
.add-program2-grid-2-4 p,
.add-program2-grid-2-5 p,
.add-program2-grid-2-6 p,
.add-program2-grid-2-7 p,
.add-program2-grid-2-8 p,
.add-program2-grid-2-9 p,
.add-program2-grid-2-10 p {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #121212;
  width: 160px;
}

.add-program2-grid-2-7 .add-program2-grid-2-71,
.add-program2-grid-2-8 .add-program2-grid-2-81,
.add-program2-grid-2-9 .add-program2-grid-2-91 {
  margin-right: 30px;
  flex: 0 0 30%;
  max-width: 0 0 30%;
}

/*-------------------------------------grid 2--------------------------------*/
.add-program2-grid-2 {
  margin-top: 20px;
  display: flex;
}
.add-program2-grid-2 .video {
  margin-bottom: 16px;
  margin-left: 20px;
}

.file-upload > input {
  display: none;
}

/*--------------------------------bottom button styling------------------------------*/
.add-program2-grid-6,
.add-program2-grid-6-1,
.add-program2-grid-6-2 {
  border: none;
  border-radius: 12px;
  background: none;
}
.add-program2-grid-6 p,
.add-program2-grid-6-1 p,
.add-program2-grid-6-2 p {
  color: #121212;
  margin: 0;
}
.add-program2-grid-6:hover,
.add-program2-grid-6-1:hover,
.add-program2-grid-6-2:hover {
  background: #0a7fd3;
}
.add-program2-grid-6:hover p,
.add-program2-grid-6-1:hover p,
.add-program2-grid-6-2:hover p {
  color: #ffffff;
}
.add-program2-grid-6-2 {
  margin-left: 10px;
  background: #014779;
}
.add-program2-grid-6-2 p {
  color: #ffffff;
}

/*-----------------------------prompt question---------------------------*/
.program-detail-prompt-question {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.program-detail-prompt h5 {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #121212;
}

.program-detail-prompt p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}

.program-detail-prompt-1-2 {
  cursor: pointer;
  padding: 0 20px;
  /* height: 30px; */
  display: inherit;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
}

.program-detail-prompt-1 {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
.program-detail-prompt-1 h6 {
  margin: 0;
  margin-left: 10px;
}
.program-detail-prompt-1-1 {
  padding-top: 8px;
  padding-left: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #121212;
}

.YTVimeo {
  padding-left: 382px;
  margin-bottom: 20px;
}
.add-program2-form-1 {
  width: 100% !important;
  background: #fff !important;
  border-radius: 8px !important;
}
.add-program2-grid-2-10 .form-label {
  width: 100%;
}
.add-program2-grid-2-10 .prompt-box {
  width: 100%;
}
.prompt-box .add-prompt-form {
  /* width: 285px !important; */
  background: #fff !important;
  border-radius: 8px !important;
}
.prompt-box {
  border-radius: 8px;
  padding: 12px;
  margin-left: 16px;
  margin-bottom: 20px;
  box-shadow: 2px 4px 8px rgba(16, 24, 40, 0.205);
}

.program-detail-prompt-2-2 {
  /* display: inherit; */
  margin-left: 10px;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #424242;
}

.program-detail-prompt-2-2:hover {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #424242;
}

.program-detail-prompt-2-3 {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #424242;
}

.program-detail-prompt-2-3:hover {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #424242;
}

.program-detail-prompt-2 {
  display: flex;
}

.add-program2-grid-100 {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.add-program2-grid-100 .form-check .form-check-input:checked {
  background-color: #529c00;
  border-color: #529c00;
}
.add-category,
.add-program2-grid-1-5 .form-group .rich-editor,
.add-program2-grid-2 .add-program2-form-1 {
  width: 100%;
}
.add-program2-grid-2 .filepond--wrapper {
  width: 100%;
}

.add-program2-grid-2-2 .add-category {
  background: #f8f9fa;
}

.form-control-50 {
  background: #f8f9fa !important;
}

.add-program2-grid .add-program2-grid-2-7 .survey-url {
  margin-top: 10px;
  margin-left: 24px;
  width: 250px;
}

.add-program2-grid .add-program2-grid-2-7 .survey-url .add-program2-form {
  margin-bottom: 10px;
  /* height: 30px; */
}

.add-program2-grid
  .add-program2-grid-2-7
  .survey-url
  .add-program2-form::placeholder {
  font-size: 12px;
}

@media only screen and (max-width: 1010px) {
  .add-program2-grid {
    padding: 0 10px;
  }
  .add-program2-grid-2-1,
  .add-program2-grid-2-2,
  .add-program2-grid-2-3,
  .add-program2-grid-2-4,
  .add-program2-grid-2-5,
  .add-program2-grid-2-6,
  .add-program2-grid-2-7,
  .add-program2-grid-2-8,
  .add-program2-grid-2-9,
  .add-program2-grid-2-10 {
    flex-direction: column;
  }
  .add-program2-grid-2-1 .col,
  .add-program2-grid-2-2 .col,
  .add-program2-grid-2-3 .col,
  .add-program2-grid-2-4 .col,
  .add-program2-grid-2-5 .col,
  .add-program2-grid-2-6 .col,
  .add-program2-grid-2-7 .col,
  .add-program2-grid-2-8 .col,
  .add-program2-grid-2-9 .col,
  .add-program2-grid-2-10 .col {
    flex: 0 0 100%;
    width: 100%;
    max-width: 0 0 100%;
    margin-bottom: 10px;
  }
  .add-program2-grid-2-1 h6,
  .add-program2-grid-2-2 h6,
  .add-program2-grid-2-3 h6,
  .add-program2-grid-2-4 h6,
  .add-program2-grid-2-5 h6,
  .add-program2-grid-2-6 h6,
  .add-program2-grid-2-7 h6,
  .add-program2-grid-2-8 h6,
  .add-program2-grid-2-9 h6,
  .add-program2-grid-2-10 h6 {
    width: 100%;
  }
  .add-program2-grid-2-1 p,
  .add-program2-grid-2-2 p,
  .add-program2-grid-2-3 p,
  .add-program2-grid-2-4 p,
  .add-program2-grid-2-5 p,
  .add-program2-grid-2-6 p,
  .add-program2-grid-2-7 p,
  .add-program2-grid-2-8 p,
  .add-program2-grid-2-9 p,
  .add-program2-grid-2-10 p {
    width: 100%;
  }
  .add-program2-grid-100 .add-program2-grid-2-71,
  .add-program2-grid-100 .add-program2-grid-2-81,
  .add-program2-grid-100 .add-program2-grid-2-91 {
    flex: 0 0 40%;
    max-width: 0 0 40%;
  }
  .prompt-box .program-detail-prompt-question .first-question {
    flex: 0 0 40%;
    width: 40%;
    max-width: 0 0 40%;
  }
  .prompt-box .program-detail-prompt-question .second-question {
    flex: 0 0 55%;
    width: 55%;
    max-width: 0 0 55%;
  }
}
@media only screen and (max-width: 750px) {
  .add-program2-grid-100 .add-program2-grid-2-71,
  .add-program2-grid-100 .add-program2-grid-2-81,
  .add-program2-grid-100 .add-program2-grid-2-91 {
    flex: 0 0 100%;
    max-width: 0 0 100%;
  }
}
@media only screen and (max-width: 850px) {
  .add-program2-grid-1 {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .add-program2-grid-1 .progress-container {
    width: 100%;
    margin-top: 10px;
  }
}