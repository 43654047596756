.btn-outline-white {
  background-color: #fff;
  color: #121212;
  border-color: #e2e8f0;
  &:hover {
    box-shadow: 0px 0px 4px 0px #014779;
  }
  &:active {
    background-color: #f9f9f9;
    box-shadow: 0px 0px 4px 0px #014779;
  }
}

.btn-outline-green {
    background-color: #fff;
    color: #529c00;
    border-color: #529c00;
    &:hover {
      background-color: rgba(143, 243, 63, 0.09);
      color: #529c00;
      border-color: #529c00;
    }
    &:active {
      color: #529c00;
      border-color: #529c00;
      background-color: rgba(143, 243, 63, 0.09);
      box-shadow: 0px 0px 4px 0px #529c00;
    }
  }

.btn-outline-red {
  background-color: #fff;
  color: #f33f3f;
  border-color: #f33f3f;
  &:hover {
    background-color: rgba(243, 63, 63, 0.0941176471);
    color: #f33f3f;
    border-color: #f33f3f;
  }
  &:active {
    color: #f33f3f;
    border-color: #f33f3f;
    background-color: rgba(243, 63, 63, 0.0941176471);
    box-shadow: 0px 0px 4px 0px #f33f3f;
  }
}
