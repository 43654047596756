.subscribeContainer {
  /* border: 1px solid #000; */
  display: flex;
  flex-direction: column;
}
.subscribeContainer p {
  margin: 0;
  padding: 0;
}
.subscribeContainer .subscribe-flex-1,
.subscribeContainer .subscribe-flex-2 {
  /* border: 1px solid #000; */
  margin: 0 auto 20px;
  padding: 6px;
  width: 45%;
}

.subscribeContainer .subscribe-flex-2 .subscribe-flex-2-1 p {
  font-size: 28px;
  font-weight: 600;
}

.subscribeContainer .subscribe-flex-2 .subscribe-flex-2-2 .form-label {
  font-size: 14px;
  font-weight: 700;
}

.subscribeContainer .subscribe-flex-2 .subscribe-flex-2-2 .stripe-input {
  width: 100%;
  min-height: 40px;
}
/* .subscribeContainer .subscribe-flex-2 .subscribe-flex-2-2 .stripe-input div {
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  background-color: #fff;
  line-height: 1.5;
} */

.subscribeContainer .subscribe-flex-2 .subscribe-flex-2-5 {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
}
.subscribeContainer .subscribe-flex-2 .subscribe-flex-2-5 p {
  /* margin: 0; */
  font-size: 15px;
  text-transform: uppercase;
}
.subscribeContainer .subscribe-flex-2 .subscribe-flex-2-5 span {
  font-size: 28px;
  font-weight: 600;
  text-transform: capitalize;
}

.subscribeContainer .subscribe-flex-2 .subscribe-flex-2-6 {
  text-align: center;
}
.subscribeContainer .subscribe-flex-2 .subscribe-flex-2-6 .btn {
  width: 50%;
  background-color: #0a7fd3;
  font-size: 20px;
  font-weight: 600;
}

@media only screen and (max-width: 1100px) {
  .account-subscribe {
    width: 60%;
  }
}
@media only screen and (max-width: 900px) {
  .account-subscribe {
    width: 80%;
  }
}
@media only screen and (max-width: 770px) {
  .account-subscribe {
    width: 100%;
  }
}

/* Subscription plan box */
.bill-plan-box {
  padding: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  gap: 1rem;
}

.bill-plan-box .indicator {
  align-self: center;
  height: 1.5rem;
  width: 1.5rem;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  padding: 3px;
  display: inline-flex;
  align-items: center;
}

.bill-plan-box .indicator > span {
  display: inline-block;
  visibility: hidden;
  background-color: #0a7fd3;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.bill-plan-box.active .indicator > span {
  visibility: visible;
}

.bill-plan-box .plan-name {
  font-size: 1.1rem;
  color: gray;
}

.bill-plan-box .price {
  font-size: 1.5rem;
  font-weight: 600;
}
