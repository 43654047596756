.user-select-option-label {
  display: flex;
  gap: 1rem;
  img {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
  }
  div > div:nth-child(2) {
    color: gray;
  }
}

.authorized-user-list-wrap {
  max-width: 800px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  overflow: hidden;
  .search-area {
    display: block;
    padding: 15px 20px;
    background-color: #f9f9f9;
    border-bottom: 1px solid #e0e0e0;
    input {
      display: block;
      width: 100%;
      padding: 5px 15px;
      border: 1.6px solid #e4e4e4;
      border-radius: 4px;
      &:focus {
        outline: 2px solid #acdcfe;
      }
    }
  }
  .content-area {
    // padding: 15px 20px;
    background-color: #fff;
  }
}

.authorized-user-list-item {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 1rem;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 15px;
  &:last-of-type {
    border-bottom: none;
  }

  .user-info {
    display: flex;
    gap: 1rem;
    img {
      height: 3rem;
      width: 3rem;
      border-radius: 2.5rem;
    }
    h5 {
      font-size: 1rem;
      margin-bottom: 0;
    }
    small {
      color: #5e5e5e;
    }
  }

  .remove-access-btn {
    padding: 5px 15px;
    background-color: #f33f3f;
    color: #fff;
    border-radius: 4px;
    font-size: 0.9rem;
    font-weight: 500;
  }
}

.no-authorized-user-block {
  max-width: 800px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 5rem 20px;
  background-color: #fff;
  text-align: center;
}
