.organization-prog {
  background: #f9f9f9;
}

.organization-prog-grid-1 h4 {
  padding-top: 30px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  color: #121212;
}

.organization-prog-grid-1 p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5a5a5c;
}

.organization-prog-1 {
  margin-top: 10px;
}

.organization-prog-grid-2 {
  margin-top: 5px;
  padding: 10px;
  box-sizing: border-box;
  width: 451px;
  height: 46px;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.organization-prog-grid-2 h6 {
  padding-top: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #121212;
}

.organization-prog-arrow-icon {
  background-color: #529c00;
  display: inline-block;
  padding: 5px 6px;
  border-radius: 100px;
}

/*-------------------------------------------grid 3-----------------------------------------*/
.organization-prog-grid-3 h4 {
  margin-top: 40px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #121212;
}

.organization-search-box {
  width: 200px;
  margin-right: 35px;
}

.organization-position {
  position: relative;
  top: 40px;
  right: -410px;
}

.organization-prog-grid-3-1 {
  display: block;
  margin-top: 20px;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  /* width: "100%"; */
  min-height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card {
  min-height: 300px !important;
  height: fit-content !important;
}

.prog-row .card .imgBox {
  width: 100%;
  height: 170px;
  aspect-ratio: 3/2;
  position: relative;
  text-align: center;
}

.prog-row .card .imgBox .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  /* aspect-ratio: 1/1; */
}

.prog-row .organization-prog-grid-3-1 a .program-type .free {
  border: none;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  background-color: #fea705;
  color: #fff;
  font-size: 12px;
  margin: 0;
  padding: 4px 20px;
  width: fit-content;
}

.prog-row .organization-prog-grid-3-1 a .program-type .paid {
  border: none;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  background-color: #529c00;
  color: #fff;
  font-size: 12px;
  margin: 0;
  padding: 4px 20px;
  width: fit-content;
}
.program-card-body {
  padding: 8px 12px;
  min-height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.organization-prog-grid-3-1 h5 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #344055;
  margin: 0;
}

.organization-prog-grid-3-1 p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  display: flex;
  align-items: flex-end;
  color: #79808a;
  margin: 0;
}

.organization-prog-grid-3-2 {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.organization-prog-grid-3-2 a {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.organization-prog-grid-3-2 h5 {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #79808a;
  margin: 0;
}

.organization-prog-arrow-icon {
  margin-left: 20px;
  background-color: #529c00;
  display: inline-block;
  padding: 5px 6px;
  border-radius: 100px;
}

.training {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  text-align: right;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #424242;
}

.organization-prog-grid-3-4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.organization-prog-grid-3-3 {
  display: flex;
  align-items: center;
}

.organization-prog-grid-3-3 h5 {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #529c00;
}

.organization-prog-grid-3-3 h6 {
  padding-left: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #a3baca;
  margin: 0;
}

/*----------------------------------------grid 4--------------------------------------*/
.organization-prog-4 {
  margin-top: 20px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.194); */
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  min-width: 290px;
}
.organization-prog-4:hover {
  box-shadow: 0px 0px 4px #014779;
}
.organization-prog-4 .organization-prog-4-1 {
  margin-right: 10px;
  width: 220px;
  /* border: 1px solid black; */
  /* height: 100px; */
}
.organization-prog-4-1 h5 {
  text-align: start;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #424242;
  margin: 0;
}

.organization-prog-4-1 p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  /* line-height: 0px; */
  color: #79808a;
  margin: 0;
}

/*------------------------------------------pop up--------------------------------------------*/
.user-wrapper {
  background-color: rgba(125, 132, 137, 0.71);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.user-popup {
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.5rem;
}

.user-popup-1 {
  padding: 20px 20px;
}

.new-user-grid-1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #014779;
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.new-user-grid-1 h5 {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  color: #fff;
}

.new-user-grid-1 h6 {
  padding-left: 40px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
}

.mb-3 {
  /* margin-left: 20px !important; */
}

.new-user-grid-1-1 {
  display: flex;
  align-items: center;
}

.new-user-grid-1-1 h6 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #121212;
  width: 120px;
}

.new-user-form {
  background: #f8f9fa !important;
  border-radius: 10px !important;
  /* width: 210% !important; */
}

.new-user-grid-1-2 {
  display: flex;
  /* align-items: center; */
}

.new-user-grid-1-2 h6 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #121212;
  width: 120px;
}

.add-textArea {
  background: #f8f9fa !important;
  /* margin-left: 20px !important; */
  /* width: 228% !important; */
}

.new-user-grid-1-3 {
  margin-top: 30px;
  display: flex;
}

.new-user-grid-1-3 h6 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #121212;
  width: 120px;
}

.new-user-grid-2 {
  width: 170px;
  margin-top: 40px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
}

.new-user-grid-2 h5 {
  padding-top: 7px;
  padding-left: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #121212;
}

.admin-prog-nav-search-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.admin-prog-nav-search-box .organization-search-box {
  /* border: 1px solid black; */
  width: 100%;
  margin-bottom: 10px;
}
.admin-prog-nav-search-box .organization-search-box .form-control {
  border: 1px solid #e2e8f0;
}
.admin-prog-nav-search-box .organization-search-box .input-group-text {
  /* border: 1px solid black; */
  margin-left: 10px !important;
  border-radius: 6px !important;
}
.admin-prog-nav-search-box
  .organization-search-box
  .input-group-text
  .search-icon {
  color: #121212;
}

/*          nav-tab styling                   */
.admin-prog-nav-search-box nav .nav .nav-link {
  border: none;
  color: #014779 !important;
}
.admin-prog-nav-search-box nav .nav .nav-link:hover {
  border-bottom: 2px solid #014779;
  color: #014779 !important;
}
.admin-prog-nav-search-box nav .nav .nav-link.active {
  border-bottom: 2px solid #0a7fd3;
  color: #0a7fd3 !important;
}

.prog-row .organization-prog-grid-3-1 .btn-group {
  width: 100%;
  margin-top: 10px;
}
.prog-row .organization-prog-grid-3-1 .btn-group .btn-restore {
  border: 1px solid #014779;
  background-color: #014779;
}

.prog-row .organization-prog-grid-3-1 .btn-group .btn-restore:hover {
  border: 1px solid #0a7fd3;
  background-color: #0a7fd3;
}

.prog-row .organization-prog-grid-3-1 .btn-group .btn-restore p {
  color: #fff;
  font-size: 10px;
  margin: 0;
}

.no-data-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.no-data-found img {
  /* height: 300px; */
  width: 250px;
  opacity: 0.8;
}
.no-data-found h2 {
  font-size: 24px;
  color: #121212;
}
.no-data-found p {
  font-size: 16px;
  color: #121212;
}

.organization-prog-grid-3-1 a .program-type {
  border: none;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-radius: 4px;
  position: absolute;
  margin-top: -10px;
  right: 0;
  width: fit-content;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.334);
}

@media only screen and (min-width: 830px) and (max-width: 1130px) {
  .prog-row .col {
    flex: 0 0 50%;
    max-width: 0 0 50%;
  }
}
@media only screen and (max-width: 920px) {
  .admin-prog-nav-search-box {
    flex-direction: column-reverse;
  }
}
@media only screen and (max-width: 870px) {
  .admin-prog {
    flex-direction: column;
  }
  .admin-prog-1 {
    margin-left: 0 !important;
    width: fit-content;
  }
}
@media only screen and (max-width: 830px) {
  .prog-row .col {
    flex: 0 0 100%;
    max-width: 0 0 100%;
  }
}
