.page__not__found {
  padding: 0.8rem;
  padding-top: 10vh;

  .page__not__found__content {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    color: #014779;
    padding: 2rem;
    border-radius: 4px;
    box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px,
      rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
    text-align: center;
  }

  h4 {
    font-family: sans-serif;
    font-size: 8rem;
    font-weight: 900;
    line-height: 1;
  }
  h6 {
    font-family: sans-serif;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
}
