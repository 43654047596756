@import "./mixins.scss";

.notifications-container {
  border: 1px solid #e9ecef;
  border-radius: 6px;
  overflow: hidden;

  .notification {
    padding: 1rem 1.5rem;
    background: #fff;
    border-bottom: 1px solid #e9ecef;
    font-family: "Inter";
    width: 100%;
    text-align: left;
    position: relative;
    @include md {
      display: flex;
      justify-content: space-between;
    }

    &.unread {
      background-color: #fcfcfc;
    }
    &.unread::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background: #8bc34a;
      width: 3px;
    }

    .notification-time {
      font-size: 0.9rem;
      color: gray;
    }
    .notification-title {
      font-weight: 500;
    }
    .notification-body {
    }

    .notification-image-container {
      aspect-ratio: 16/9;
      max-width: 150px;
      img {
        height: 100%;
        width: 100%;
        object-position: center;
        object-fit: cover;
        border-radius: 4px;
      }
    }
  }
}

// Invitation Notification
.invitation-notifications-container {
  border: 1px solid #e9ecef;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 1.5rem;
  
  .invitation-notification {
    padding: 1rem 1.5rem;
    background: #fff;
    border-bottom: 1px solid #e9ecef;
    font-family: "Inter";
    @include md {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    }
}
