.program__placeholder {
  background-color: white;
  border: 1px solid #f5f5f5;
  border-radius: 6px;
  overflow: hidden;
  padding: 1rem;

  figure {
    background-color: #f5f5f5;
    aspect-ratio: 16/9;
    border-radius: 6px;
    position: relative;
    overflow: hidden;

    .activity {
      position: absolute;
      left: 10%;
      top: -5%;
      height: 150%;
      width: 5rem;
      filter: blur(100%);
      transform: rotate(15deg);
      background-image: linear-gradient(
        to left,
        rgba(251, 251, 251, 0.05),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.6),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.05)
      );
      background-image: -moz-linear-gradient(
        to left,
        rgba(251, 251, 251, 0.05),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.6),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.05)
      );
      background-image: -webkit-linear-gradient(
        to left,
        rgba(251, 251, 251, 0.05),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.6),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.05)
      );
      animation: shine 1s infinite;
      z-index: 45;
    }
  }
  .body {
    padding: 1rem 0;
    .placeholder__title {
      background-color: #f5f5f5;
      width: 75%;
      height: 1rem;
      margin-bottom: 1rem;
    }
    p {
      background-color: #f5f5f5;
      height: 0.9rem;
      width: 100%;
      margin-bottom: 5px;
    }
  }
}

@keyframes shine {
  0% {
    left: -45%;
  }
  100% {
    left: 100%;
  }
}
