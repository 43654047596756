.publication__status {
  padding: 0.5rem 1rem;
  border-radius: 2.5rem;
  display: inline-flex;
  align-items: center;
  gap: 10px;

  &.published {
    background-color: #529c00;
    color: #ffffff;
  }

  &.draft {
    background-color: #ffdd76;
    color: #241602;
    svg {
      animation: blink 1s infinite;
    }
  }

  svg {
    font-size: 1.2rem;
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
  }
}
