*{
    margin: 0;
    padding: 0;
}

.organization-billing{
    background: #F9F9F9;
}

.organization-billing-grid-1 h4{
    padding-top: 30px;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    color: #121212;
}

.organization-billing-grid-1 p{
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5A5A5C;
}

.organization-billing-1{
    margin-top: 10px;
}

.organization-billing-grid-2{
    margin-top: 5px;
    padding: 10px;
    box-sizing: border-box;
    width: 451px;
    height: 46px;
    background: #FFFFFF;
    border: 1px solid #E2E8F0;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.organization-billing-grid-2 h6{
    padding-top: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #121212;
}

.organization-billing-arrow-icon{
    background-color: #529C00;
    display: inline-block;
    padding: 5px 6px;
    border-radius: 100px;
}

/*-------------------------------------------grid 3-----------------------------------------*/
.organization-billing-grid-3 h4{
    margin-top: 40px;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #121212;
}

.organization-billing-grid-3-2{
    margin-top: 35px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

.organization-billing-grid-3-2 h6{
    width: 150px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #121212;
}

.organization-billing-grid-3-3{
    /* margin-left: 50px; */
    width: 266px;
    display: flex;
    align-items: center;
}

.organization-billing-grid-3-3 h5{
    padding-right: 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #121212;
}

.organization-billing-grid-3-3 h6{
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #121212;
}

.organization-billing-grid-3-4{
    /* width: 170px; */
    padding: 0px 20px;
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #E2E8F0;
    border-radius: 12px;
}

.organization-billing-grid-3-4 h5{
    padding-top: 7px;
    padding-left: 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #121212;
}

.organization-billing-grid-4{
    margin-top: 35px;
    display: flex;
    /* align-items: center; */
    /* justify-content: space-between; */
}

.organization-billing-grid-4 h6{
    width: 150px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #121212;
}

.organization-billing-grid-4-1{
    width: 266px;
}

.organization-billing-grid-4-1 h5{
    width: 200px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #121212;
}

.organization-billing-grid-4-2{
    /* margin-left: 100px; */
    height: 40px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #E2E8F0;
    border-radius: 12px;
}

.organization-billing-grid-4-2 h5{
    padding-top: 7px;
    padding-left: 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #121212;
}

.organization-billing-grid-5{
    display: flex;
    margin-top: 50px;
}

.organization-billing-grid-5 h6{
    padding-top: 10px;
    width: 150px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #121212;
}

/*------------------------------------table------------------------------*/
thead tr th{
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #121212;
}

tbody tr th{
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #121212;
}

.organization-billing-table{
    margin-left: 35px !important;
    border-bottom-width: 0 !important;
}

.table>:not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 0px !important;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

