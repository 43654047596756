.program-feedback {
  background-color: #fff;
  padding: 20px 20px;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;

  h4 {
    color: #062e4b;
    font-size: 1.3rem;
  }

  textarea {
    display: block;
    width: 100%;
    background-color: #fafafa;
    padding: 10px 15px;
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    margin-bottom: 1rem;
    &:focus {
        background-color: #fff;
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
    }
  }

  button {
    padding: 10px 15px;
    background-color: #014c81;
    color: #fff;
    font-weight: 600;
    border-radius: 4px;
    outline: none;
    border: 0;
    &:hover {
      opacity: 0.9;
    }
  }
}
