

.wizard-nav .nav .nav-link {
    border: none;
    color: #014779 !important;
  }
  .wizard-nav .nav .nav-link:hover{
    border-bottom: 2px solid #014779;
    color: #014779 !important;
  }
  .wizard-nav .nav .nav-link.active {
    border-bottom: 2px solid #0a7fd3;
    color: #0a7fd3 !important;
  }