.resource-preview {
  display: flex;
  gap: 1rem;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  background-color: #fff;

  .file-type {
    font-size: 3.5rem;
    color: rgb(223, 57, 57);
  }

  .info {
    overflow: hidden;
    .filename {
      overflow-wrap: break-word;
    }
  }

  .action {
    margin-left: auto;
    display: flex;
    gap: 1rem;

    a {
      color: rgb(44, 130, 211);
    }

    button {
      outline: none;
      border: none;
      color: rgb(223, 57, 57);
      background-color: transparent;
    }
  }
}
