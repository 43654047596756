.progress-container .progress-text p {
    color: #a3baca;
    font-size: 18px;
    margin: 0;
  }
  .progress-container .progress-text span {
    color: #529c00;
    font-size: 28px;
    margin: 0 2px;
    font-weight: bold;
  }
  .progress-container .progress .progress-bar {
    background-color: #529c00;
  }