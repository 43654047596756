/* .close-button{
    background:#014779;
    padding: 20px;
} */
.modal-content {
  border-radius: 18px;
  border: none;
}
.modal-body {
  background-color: #f9f9f9;
  border-radius: 18px;
  border: none;
  padding: 0;
}
.login-popup-1 {
  /* width: 470px; */
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
}

.login-grid-1 h5 {
  margin-bottom: 16px;
  padding-top: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  color: #000000;
}

.login-grid-1 p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  margin-bottom: 24px;
  color: #000000;
}

.right-arrow-center {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.right-arrow {
  display: inline-block;
  background: #014779;
  padding: 20px;
  border-radius: 50px;
}
.login-grid-2 {
  margin-bottom: 20px;
}
.login-grid-2 input {
  border: 1px solid #e2e8f0;
  border-radius: 16px;
  box-sizing: border-box;
  background-color: #ffffff;
  font-size: 14px;
  padding: 12px;
}

.login-button {
  margin-top: 20px;
  margin-left: 25%;
  width: 50%;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #ffffff;
  background-color: #014779;
  border-radius: 16px;
}
.forget-box .forget-btn,
.login-btn .group .signup-btn {
  background: transparent;
  border: none;
  text-align: center;
  padding-top: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #529c00;
  cursor: pointer;
}

.login-btn .group .signup-btn{
  font-size: 16px;
}


.login-btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.login-btn .group{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.login-btn .group p{
margin: 0;
margin-right: 10px;
color:black ;
font-size: 16px;
}

.login-btn  p {
  margin: 0;
  font-size: 15px;
  color: #666;
}

.login-btn .center{
  text-align: center;
  /* max-width: 300px; */
}

.forget-box{
  display:flex ;
  flex-direction: row;
  justify-content: flex-end;
}

