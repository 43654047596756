
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .pagination button {
    margin: 0 5px;
    padding: 3px 10px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .pagination button.active {
    background-color: #014779;
    color: #fff;
    position: relative;
  }

 
  .pagination button.active::before {
    content: '';
    position: absolute;
    bottom: -2px; 
    left: 0;
    width: 100%;
    height: 4px; 
  }
  