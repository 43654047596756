.modal-content {
  border-radius: 18px;
  border: none;
}

.modal-body {
  border-radius: 18px;
  background-color: #f9f9f9;
}
.signup-grid-1 h5 {
  padding-top: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #000000;
  margin-top: 30px;
}

.signup-grid-1 p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #000000;
}

.right-arrow-center {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.right-arrow {
  display: inline-block;
  background: #014779;
  padding: 20px;
  border-radius: 50px;
}

.signup-container {
  margin-top: 30px;
}

.signup-grid-1-1 {
  margin-top: 10px;
}

.signup-grid-1-1 input {
  border: 1px solid #e2e8f0;
  border-radius: 16px;
  box-sizing: border-box;
  background-color: #ffffff;
  font-size: 14px;
  padding: 12px;
  /* margin-bottom: 20px; */
}

.signup-grid-1-2 {
  margin-top: 20px;
}

.signup-grid-1-2 input {
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  background-color: #ffffff;

  border-radius: 16px;
  /* margin-bottom: 20px; */
  font-size: 14px;
  padding: 12px;
}

.error-text {
  margin-left: 10px;
}

.error-first-name {
  margin-bottom: 20px;
}

.password-help-text {
  font-size: 12px;
  margin-bottom: 10px !important;
}

.signup-password input {
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  background-color: #ffffff;

  border-radius: 16px;
  font-size: 14px;
  padding: 12px;
}
.signup-password input:first-child {
  margin-top: 8px;
}
.signup-password {
  margin-bottom: 20px;
}

.signup-button {
  margin-top: 10px;
  margin-left: 25%;
  width: 50%;
  text-align: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #ffffff;
  background-color: #014779;
  border-radius: 16px;
  /* --bs-btn-padding-x: 0 !important; */
}

.signup-grid-2 {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.signup-grid-2 p {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #000000;
}

.signup-grid-2 h5 {
  padding-top: 5px;
  padding-left: 5px;
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #529c00;
}

.signup-first-name{
  margin-right: 20px;
}

.group{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;
}

.group .Login-btn{
  margin-left: 15px;
  color: #529c00;
}