*{
    padding: 0;
    margin: 0;
}

.notification{
    background: #F9F9F9;
}

.noti-grid-1{
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.noti-grid-1-1 h4{
    padding-top: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    color: #121212;
}

.noti-grid-1-1 p{
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #5A5A5C;
}

.noti-cross-icon{
    background-color: #529C00;
    display: inline-block;
    padding: 10px;
    border-radius: 100px;
}

.noti-grid-1-2{
    margin-right: 40px;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 110px;
    /* height: 52px; */
    background: #FFFFFF;
    border: 1px solid #E2E8F0;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
}

.noti-grid-1-2 h5{
    padding-top: 7px;
    padding-left: 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #121212;
}

/*-----------------------------------------------------grid 2----------------------------------------------------*/
.noti-grid-2{
    display: flex;
    align-items: center;

}

.noti-grid-2-1{
    width: 100px;
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    color: #424242;
}

.noti-grid-2-2{
    /* padding-left: 30px; */
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.noti-grid-2-2 p{
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    color: #121212;
}

.noti-grid-2-2 button{
    position: relative;
    top: -7px;
    padding-left: 10px;
    background: transparent;
    border: none;
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    text-decoration-line: underline;
    color: #014779;
}

.noti-grid-2-3{
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    justify-content: center;
    margin-left: 50px;
}

.noti-grid-2-3 h6{
    padding-top: 6px;
    padding-left: 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: #424242;
}
















