.support-feedback-modal {
    .screenshot-selector {
        border-radius: 6px;
        overflow: hidden;
        border: 1px solid #ced4da;
        
        .preview {
            aspect-ratio: 16/5;
            width: 100%;
            background-color: #f7f7f7;
            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
                object-position: center;
            }
            .image-placeholder {
                font-size: 2.5rem;
                display: grid;
                height: 100%;
                width: 100%;
                place-items: center center;
                color: #2196f3;
            }
        }
        .select-btn {
            display: block;
            background: #2196f3;
            color: #fff;
            text-align: center;
            padding: 10px 5px;
            cursor: pointer;
            &:hover {
                opacity: .9;
            }
        }
    }
}