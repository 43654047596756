*{
    padding: 0;
    margin: 0;
}

.addprogram-1a{
    background: #F1F1F1;
    height: 100%;
}

.addprogram-1a-header{
    background: #014779;
}

.addprogram-1a-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #014779;
    padding: 20px 40px;
}

.addprogram-1a-header h5{
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #fff;
}

/*-------------------------------------------grid 1--------------------------------------*/
.addprogram-1a-grid{
    padding: 20px 40px;
}

.addprogram-1a-grid-1{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.addprogram-1a-grid-1-1 h5{
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    color: #121212;
}

.addprogram-1a-grid-1-1 p{
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 0;
    color: #121212;
}

/*------------------------------------------grid 2-------------------------------------*/
.addprogram-1a-grid-2{
    margin-top: 20px;
    padding: 10px;
    background: #FFFFFF;
    border: 1px solid #E2E8F0;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    width: "100%";
}

.addprogram-1a-grid-2 h6{
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    text-align: right;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #424242;
}

.addprogram-1a-grid-2 h5{
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #344055;
}

.addprogram-1a-grid-2 p{
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    display: flex;
    align-items: flex-end;
    color: #79808A;
}

.addprogram-1a-grid-2-1{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

.addprogram-1a-grid-2-2{
    display: flex;
    align-items: center;
}

.addprogram-1a-grid-2-2 h5{
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #529C00;
}

.addprogram-1a-grid-2-2 h6{
    padding-top: 6px;
    padding-left: 5px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #A3BACA;
}

.addprogram-1a-grid-2-3{
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.addprogram-1a-grid-2-3 h5{
    padding-top: 8px;
    padding-left: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #79808A;
    }

.lib-arrow-icon{
    margin-left: 20px;
    background-color: #529C00;
    display: inline-block;
    padding: 8px 9px;
    border-radius: 100px;
}