@import "./mixins.scss";

/* Video Recorder */
.video-recorder-wrap {
  // position: relative;
  // padding-top: 56.25%;
}
.video-recorder-container {
  // position: absolute;
  // top: 0;
  // left:0;
  // width: 100%;
  // height: 100%;
  position: relative;
  width: 100%;
  max-width: 600px;
  aspect-ratio: 16/9;

  .video-recorder {
    width: 100%;
  }

  // Remove mirror effect on video preview
  video {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg); /* Safari and Chrome */
    -moz-transform: rotateY(180deg); /* Firefox */
  }
}

.video-recorder-container .main-caption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  @include md {
    font-size: 1.4rem;
  }
}

.video-recorder-container .caption {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1rem;
  text-align: center;
  color: #fff;
  font-weight: 700;
  overflow-wrap: break-word;
  word-break: break-word;
  width: 100%;
  padding: 15px 0;
  margin: 0;
  text-shadow: 0 0 3px #000, 0 0 3px #000, 0 0 3px #000, 0 0 3px #000;
  @include md {
    font-size: 20px;
  }
}

/* .video-recorder-container .nav-arrows {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
  } */

.video-recorder-container .nav-arrow {
  position: absolute;
  top: 50%;
  color: #fff;
  padding: 5px;
  font-size: 20px;
  background-color: #8b9399;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 60px;
  cursor: pointer;
  transform: translateY(-50%);
  @include md {
    font-size: 30px;
  }
}

.video-recorder-container .prev-arrow {
  left: 0;
  margin-left: 10px;
}
.video-recorder-container .next-arrow {
  right: 0;
  margin-right: 10px;
}

.video-recorder-container .nav-arrow:hover {
  background-color: rgb(0 62 114);
  color: #ffffff;
}

.video-recorder-container .recorder-overlay {
  position: absolute;
  inset: 0;
  /* background-color: #ccc; */
  z-index: 999;
}

/* Position the record indicator to bottom right of video player */
.vjs-record.video-js .vjs-control.vjs-record-indicator {
  bottom: 3ex !important;
  top: unset !important;
  opacity: 1;
  font-size: 14px !important;
  /* width: 0.8em;
    height: 0.8em;
    background-color: #ff625e;
    line-height: 1.1 em;
    border-radius: 50%;
    right: 2.5ex;
    top: 3ex; */
}

.vjs-record.video-js .vjs-control.vjs-record-indicator.vjs-hidden::before,
.vjs-record.video-js .vjs-control.vjs-record-indicator.vjs-hidden::after {
  display: none !important;
}

.prompt-overlay {
  display: flex;
  /* position: relative; */
  justify-content: space-between;
  width: 684px;
  height: 65px;
  margin: auto;

  background: #227fc1;
}

.prompt-overlay article span,
.prompt-overlay article span h2 {
  color: #e4e1e1;
}

.video-btn-actions {
  display: flex;
}

.custom-modal .uk-form-icon.uk-form-stacked > input {
  width: 492px;
}
