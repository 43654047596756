.single-prompt-modal .modal-header {
    padding: 20px 40px;
    background: #014779;
  }

  .single-prompt-modal .modal-header .modal-title{
   color:#fff ;
  }

  .single-prompt-modal .modal-body {
    border-radius: 0;
    padding: 20px 40px;
  }

  
  .single-prompt-modal .modal-body .form-control {
    margin-right: 10px;
  }
  .single-prompt-modal .modal-body .program-detail-prompt-2-2 {
    margin: 0;
  }

.single-prompt-modal .modal-footer .btn{
    border: none;
    border-radius: 12px;
    background: none;
}

.single-prompt-modal .modal-header .btn-close{
  background-color: #fff;
}

.single-prompt-modal .modal-footer .close-btn{
    color: #fff;
    background-color: #bb2d3b;
}
.single-prompt-modal .modal-footer .save-btn{
   color: #fff;
   background: #014779;
}

.single-prompt-modal .modal-footer .save-btn:hover{
  background: #0a7fd3;
}