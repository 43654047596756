@font-face {
  font-family: "sf-pro"; /* Replace 'YourFontName' with a suitable name for your font */
  src: url("./../fonts/sf-pro/SFPRODISPLAYREGULAR.OTF") format("opentype"); /* Replace 'YourFontFile' with the actual file name */
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: rgb(249, 249, 249);
  /* background-color: #f4f4f4 ; */
}
.app__container {
  display: flex;
  height: 100vh;
}
.bar-btn {
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: #ffffff;
  display: none;
  padding: 6px 8px;
  position: absolute;
  top: 8px;
  left: 5px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.233); */
}
.app__container.authenticated .bar-btn {
  top: 20px;
}
.bar-btn .bar {
  color: #000;
  font-size: 20px;
}

.x-btn {
  border: 5px solid #f4f4f4;
  border-radius: 50%;
  background: #ffffff;
  display: none;
  padding: 4px 5px;
  position: fixed;
  top: 12px;
  left: 206px;
  z-index: 111111;
}
.x-btn .xmark {
  color: #bb2d3b;
  font-size: 20px;
}

.app__sidebar {
  display: block;
  min-width: 266px;
  max-width: 266px;
  height: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  overflow-y: auto;
}

.app__container > .main {
  flex-grow: 1;
}

.rich-editor {
  /* border: 1px solid #ccc; */
}

.rdw-editor-toolbar {
  border: 1px solid #ced4da !important;
  border-radius: 6px !important;
}

.rdw-editor-main {
  border: 1px solid #ced4da;
  border-radius: 8px;
  background: #f8f9fa91;
  /* min-height: 200px !important; */
  padding-left: 5px;
  padding-right: 5px;
}

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 25px;
  text-align: center;
}

.dropzone p {
  font-size: 18px;
  color: #666;
}
.main {
  margin-left: 266px;
  width: 100%;
}

.prompt-list {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.choose-prompt form {
  display: inline-block;
}
.prompt-icon .disabled {
  background-color: gray;
}
h2.promt-quest {
  overflow-wrap: break-word;
  word-break: break-word;
  width: 500px;
  /* background-color: #5c5858;
     color: white;
     border-radius: 10px; */
  padding: 15px !important;
  /* border: 10px solid; */
  text-shadow: 0 0 3px black, 0 0 3px black, 0 0 3px black, 0 0 3px black;
}

.myVideo-dimensions {
  width: 850px;
  height: 500px;
}

.video-js .vjs-control-bar {
  display: none;
  width: unset !important;
  position: absolute;
  bottom: 10px !important;
  left: 42% !important;
  right: 42% !important;
  /* height: 3em; */
  background-color: #ccc0 !important;
  color: white;
  justify-content: center;
  text-shadow: 0 0 3px #000, 0 0 3px #000, 0 0 3px #000, 0 0 3px #000;
  /* background-color: rgba(43,51,63,.7); */
  font-size: 18px;
  /* letter-spacing: 0px; */
  /* -webkit-text-stroke-width: 0.5px; */
  /* -webkit-text-stroke-color: black; */
}

@media only screen and (max-width: 650px) {
  .app__sidebar {
    display: none;
    min-width: 0;
    /* width: 100px !important; */
  }
  .sidebar-show {
    display: flex;
    z-index: 1111;
  }
  .main {
    /* margin-left: 100px; */
    margin-left: 0;
  }
  .bar-btn,
  .x-btn {
    display: block;
  }
}
@media only screen and (max-width: 550px) {
  .bar-btn {
    display: block;
  }
}

/* Buttons */
.btn {
  border-radius: 12px;
}

.btn-accent {
  background-color: #014779;
  display: inline-block;
  color: #fff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px solid #014779;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-accent:hover {
  background-color: #0a7fd3;
  color: #fff;
  border-color: #0a7fd3;
}
.btn-accent[disabled] {
  background-color: #d9d9d9;
}

.icon-btn {
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e2e8f0;
}
.icon-btn .icon {
  color: #529c00;
  /* font-size: 16px; */
}

.icon-btn:hover {
  background-color: #fff;
  box-shadow: 0 0 4px #014779;
}

/* Aspect ratio */
[class*="ratio-"] {
  display: block;
  position: relative;
}

[class*="ratio-"] > * {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.aspect-ratio-wide {
  padding-top: 56.25%;
}

.aspect-ratio-square {
  padding-top: 100%;
}

/* Font Family */
.font-secondary {
  font-family: "Inter";
}

/*
* =================
* Tab counts badge
* =================
*/
.tab-counts-badge {
  margin-left: 0.5rem;
  padding: 3px 8px;
  font-size: 0.9rem;
  background-color: #084f82;
  color: #fff;
  border-radius: 1rem;
}

/*
* ====================
* Nav Tabs Scrollable
* ====================
*/
.nav-tabs-scrollable {
  flex-wrap: nowrap;
  overflow-x: auto;
}
.nav-tabs-scrollable .nav-link {
  white-space: nowrap;
  border-bottom: 2px solid transparent;
  margin-bottom: 0;
}
.nav-tabs-scrollable::-webkit-scrollbar {
  display: none;
}
