* {
  padding: 0;
  margin: 0;
}

.answer {
  background-color: #f4f4f4;
}

.answer-grid-1 h4 {
  padding-top: 30px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #121212;
}

.answer-grid-1 p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #79808a;
  text-align: justify;
}

.answer-grid-1 p button {
  border: none;
  background: none;
  margin: 0 4px;
  font-size: 14px;
}

/*---------------------------------answer grid 2----------------------------------*/
.answer-1 {
  margin-top: 36px;
}

.answer-grid-2 {
  margin-top: 10px;
  padding: 10px;
  box-sizing: border-box;
  /* width: 451px; */
  width: 100%;
  height: 46px;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.answer-grid-2 h6 {
  padding-top: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #121212;
}

.answer-arrow-icon {
  background-color: #529c00;
  display: inline-block;
  padding: 5px 6px;
  border-radius: 100px;
}

/*------------------------------------------answer grid 3-------------------------------------------*/
.answer-grid-3 {
  padding: 0 0px;
}

.answer-grid-3 h4 {
  padding-top: 30px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #121212;
}
.answer-grid-3-1 {
  text-align: justify;
}
.answer-grid-3-1 p {
  padding: 20px 30px;
  width: 522px;
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #121212;
}

.answer-grid-3-2 {
  margin-top: 10px;
}

.answer-grid-3-2 h5 {
  /* padding-left: 30px; */
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #121212;
}

.answer-grid-3-3 {
  /* padding-left: 30px; */
  /* padding: 5px 15px;
      box-sizing: border-box;
      width: 292px;
      height: 32px;
      background: #FFFFFF;
      border: 1px solid #E2E8F0;
      border-radius: 12px;
      margin-left: 20px;
      margin-top: 15px; */
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.answer-grid-3-3 h6 {
  padding-left: 20px;
  padding-top: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #121212;
}

.answer-grid-3-5 {
  text-align: end;
}

.answer-grid-3-6 {
  display: inline-block;
  background: #014779;
  border-radius: 12px;
  padding: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
}

.after-survey-modal .modal-header {
  background-color: #014779;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  padding: 16px 20px;
}
.after-survey-modal .modal-header .modal-title {
  color: #ffffff;
  margin: 0;
}
.after-survey-modal .modal-header .btn-close {
  background-color: #ffffff;
}
.after-survey-modal .modal-body {
  padding: 16px 20px;
  border-radius: 0;
  background-color: #ffffff;
}
.after-survey-modal .modal-footer {
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
}

.after-survey-modal .modal-footer .btn {
  background-color: #014779;
  border: none;
}
.after-survey-modal .modal-footer .btn:hover {
  background-color: #0a7fd3;
  border: none;
}
