@import "./mixins.scss";

.countdown-wrapper {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.countdown-box {
  border: 2px solid #e6e6e6;
  border-radius: 6px;
  color: #424040;
  text-align: center;

  .value {
      padding: 0.5rem 1rem 0 1rem;
      font-size: 1.2rem;
      font-weight: 600;
      font-family: Arial, Helvetica, sans-serif;
      letter-spacing: 0.045rem;
      @include md {
        font-size: 1.2rem;
        padding: 1rem;
      }
      @include lg {
        font-size: 1.5rem;
        padding: 0.5rem 1.5rem 0 1.5rem;
      }
      @include xl {
        font-size: 1.5rem;
      }
  }

  .unit {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    color: #444546;
    font-size: 0.7rem;
    @include sm {
        font-size: 0.8rem;
    }
    @include md {
        font-size: 0.9rem;
    }
  }
}
