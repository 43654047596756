.close-button {
  background-color: #014779;
  padding: 0;
}
.close-button .exercise-popup-header {
  width: 100%;
  padding: 20px 100px;
}
.close-button .exercise-popup-header .circle-x-mark {
  font-size: 24px;
}
.close-button .exercise-popup-header .exercise-popup-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.close-button .exercise-popup-header .exercise-popup-1 p {
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}
.close-button
  .exercise-popup-header
  .exercise-popup-1
  .exercise-popup-close-btn {
  color: #ffffff;
  font-size: 18px;
}
.modal-body .exercise-popup {
  padding: 30px 100px;
}

.modal-body .exercise-popup .exercise-popup-5 nav .nav {
  margin-bottom: 20px;
}
/* .modal-body .video {
  border-radius: 12px;
} */
.modal-body .video .video-js {
  border-radius: 12px;
}
.modal-body .video .video-js .vjs-control-bar {
  display: none !important;
}

.modal-body .exercise-popup-6 {
  margin-top: 20px;
}
.modal-body .exercise-popup-6 .exercise-button {
  background-color: #014779;
  border: none;
  border-radius: 12px;
}

@media only screen and (max-width: 990px) {
  .close-button .exercise-popup-header {
    padding: 20px 30px;
  }
  .modal-body .exercise-popup {
    padding: 30px 30px;
  }
}
