.download-app-mini-banner {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
  padding: 20px 5px 10px 5px;
  color: #05243c;
  border-top: 2px solid #cdcdcd;
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  gap: 1rem;
  align-items: center;

  .encap-icon {
    display: inline-block;
    display: grid;
    place-items: center center;
    background-color: #014779;
    color: #fff;
    padding: 10px;
    border-radius: 4px;
    svg {
      display: inline;
      height: 1.3rem;
      width: 1.3rem;
    }
  }

  .get-btn {
    padding: 8px 13px;
    background-color: #014779;
    color: #fff;
    border-radius: 2.5rem;
    letter-spacing: 0.5px;
  }
}
