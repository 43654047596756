.organization-invitation-container {
  max-width: 600px;
  margin: 50px auto 0 auto;
  background-color: #fff;
  padding: 2rem 1.5rem;
  border: 1px solid #ebebeb;
  border-radius: 6px;

  .setup-account {
    .step-icon-wrap {
      display: flex;
      justify-content: center;
      margin-bottom: 3rem;

      .step-icon {
        height: 90px;
        width: 90px;
        border-radius: 25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0a7fd3;

        svg {
          height: 50px;
          width: 50px;
          color: #fff;
        }
      }
    }
    .step-heading {
      font-family: "sf-pro";
      font-weight: 600;
      letter-spacing: 0.1rem;
      text-align: center;
      font-size: 1.4rem;
      color: #153060;
      margin-bottom: 2rem;
    }

    .form-control {
      padding: 16px 12px 16px 12px;
      border-radius: 12px !important;
      border-color: transparent;
      border: 1px solid #ebebeb;
      background-color: #f9f9f9;
      &:focus {
        background-color: #fff;
      }
    }
    .btn {
      border-radius: 12px !important;
      padding: 18px 32px 18px 32px;
      font-size: 22px;
      line-height: 24px;
      width: 100%;
      font-family: "sf-pro";
      letter-spacing: 0.025rem;
    }
    .btn-primary {
      background-color: #0a7fd3;
    }
  }
}
