.org-stepper {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  
  .point {
    height: 35px;
    width: 35px;
    background: #0A7FD3;
    color: #fff;
    border-radius: 2.5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &.completed {
        background-color: #529C00;
    }

  }
}
