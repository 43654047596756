.cover-image-uploader {
  background-color: #fff;
  border-radius: 1rem;
  padding: 1rem;
  max-width: 600px;
  box-shadow: 1px 1px 1px #f5f5f5;

  .preview-wrap {
    aspect-ratio: 6/4;
    img {
      height: 100%;
      width: 100%;
      border-radius: 8px;
      object-fit: cover;
    }

    .zone {
      height: 100%;
      width: 100%;
      background: #f7f7f7;
      border-radius: 8px;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .icon {
        font-size: 3rem;
        color: #3a424c;
      }
    }
  }
  .uploading-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    font-weight: 500;
    font-size: 1.1rem;
  }

  label {
    display: block;
    background-color: #0a7fd3;
    color: #fff;
    padding: 10px 15px;
    border-radius: 0.8rem;
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 1.1rem;
    cursor: pointer;
  }
}
