

.custom-button {
  background-color: #014779;
  color: #fff;
  width: 600px;
}


  .custom-form-group .custom-back-link {
    color: #014779;
  }
  
  .custom-form-group .custom-back-link .custom-icon {
    color: #014779; 
  }
  
  
@media (max-width: 1440px) {
  .custom-button {
    width: 100%;
  }
}


@media (max-width: 576px) {
  .custom-form-group {
    flex-direction: column; 
    align-items: center; 
  }

  .custom-button {
    width: 100%;
  }
}
