@import "./mixins.scss";

.view-response-page {
  .section-heading-big {
    font-family: "Inter";
    font-weight: 700;
    font-size: 1.3rem;
  }
  .sub-section {
    font-family: "Inter";
    font-weight: 600;
    font-size: 1.2rem;
    color: #666464;
  }
  .items-list-wrap {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    > button {
      background: #fff;
      border: 1px solid #e2e8f0;
      padding: 10px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .item-meta {
        text-wrap: nowrap;
      }
      .submitted-date {
        color: #6c757d;
      }
      .item-arrow-icon {
        margin-left: 10px;
        background-color: #0980d4;
        border-radius: 50%;
        padding: 6px 7px;
        font-size: 12px;
        vertical-align: middle;
      }
      &.active {
        background-color: #0980d4;
        color: #fff;
        border-color: #0980d4;
        font-weight: 500;
        .submitted-date {
          color: #fff;
        }
        .item-arrow-icon {
          background-color: #fff;
          color: #529c00;
        }
      }

      &.not-completed {
        background-color: #dddddd;
        opacity: .7;
      }
    }
  }

  .section-box {
    background: #fff;
    padding: 1rem;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
  }
  .student-box {
    display: flex;
    gap: 1rem;
    img {
      height: 5rem;
      width: 5rem;
      border-radius: 2.5rem;
    }
  }

  .item__title {
    font-family: "Inter";
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 0.2rem;
  }
}
