@import "./mixins.scss";

.my-badges {
  gap: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @include md {
    grid-template-columns: repeat(3, 1fr);
  }
  @include lg {
    grid-template-columns: repeat(4, 1fr);
  }
  @include xl {
    grid-template-columns: repeat(5, 1fr);
  }

  .badge-item {
    background-color: #fff;
    border-radius: 4px;
    padding: 10px 10px;
    text-align: center;
    font-size: 0.9rem;
    .badge-square {
      position: relative;
      aspect-ratio: 1/1;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .badge-title {
        margin-top: .5rem;
    }
  }
}
