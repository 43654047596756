.organization-header {
  color: #ffffff;
  padding-left: 30px !important;
  background-color: #529c00;
  text-align: center;
}

.organization-header .btn {
  background-color: #ffffff;
  color: #529c00;
  border: 1px solid #ffffff;
  border-radius: 4px !important;
  padding: 4px 10px;
  margin-left: 20px;
  font-size: 0.9rem;
  transition: all 150ms;
}

.organization-header .btn:hover {
  background-color: #fafafa;
  outline: 2px solid #cdfc98;
}
