.bs-title h6 {
  font-size: 24px;
  font-weight: 700;
}

.bs-content {
  /* border: 1px solid red; */
  margin-top: 30px;
}
.bs-content p {
  margin: 0;
}
.bs-content .button-plus {
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  padding: 6px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bs-content .button-plus p {
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
}

.bs-content .bs-content-1,
.bs-content .bs-content-2,
.bs-content .bs-content-3,
.bs-content .bs-content-4,
.bs-content .bs-content-5,
.bs-content .bs-content-6,
.bs-content .bs-content-7 {
  /* border: 1px solid black; */
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.bs-content .bs-content-1 .label,
.bs-content .bs-content-2 .label,
.bs-content .bs-content-3 .label,
.bs-content .bs-content-4 .label,
.bs-content .bs-content-5 .label,
.bs-content .bs-content-6 .label,
.bs-content .bs-content-7 .label {
  flex: 0 0 30%;
  max-width: 0 0 30%;
  width: 30%;
}
.bs-content .bs-content-1 .label p,
.bs-content .bs-content-2 .label p,
.bs-content .bs-content-3 .label p,
.bs-content .bs-content-4 .label p,
.bs-content .bs-content-5 .label p,
.bs-content .bs-content-6 .label p,
.bs-content .bs-content-7 .label p {
  font-size: 14px;
  font-weight: 600;
}

.bs-content .bs-content-1 .input-value,
.bs-content .bs-content-2 .input-value,
.bs-content .bs-content-3 .input-value,
.bs-content .bs-content-4 .input-value,
.bs-content .bs-content-5 .input-value,
.bs-content .bs-content-7 .input-value {
  flex: 0 0 70%;
  max-width: 0 0 70%;
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.bs-content .bs-content-1 .input-value .input-value-1,
.bs-content .bs-content-2 .input-value .input-value-1,
.bs-content .bs-content-3 .input-value .input-value-1,
.bs-content .bs-content-4 .input-value .input-value-1,
.bs-content .bs-content-6 .input-value .input-value-1,
.bs-content .bs-content-7 .input-value .input-value-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bs-content .bs-content-1 .input-value .input-value-1 p,
.bs-content .bs-content-2 .input-value .input-value-1 p,
.bs-content .bs-content-3 .input-value .input-value-1 p,
.bs-content .bs-content-6 .input-value .input-value-1 p,
.bs-content .bs-content-7 .input-value .input-value-1 p {
  font-size: 18px;
  font-weight: 600;
}
.bs-content .bs-content-4 .input-value .input-value-1 p,
.bs-content .bs-content-5 .input-value .input-value-1 p {
  font-size: 14px;
  font-weight: 600;
}
.bs-content .bs-content-1 .input-value .input-value-1 .cancel-plan {
  color: #b00a0a;
  font-size: 14px;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  background-color: #ffffff;
  padding: 6px 10px;
  margin-left: 10px;
}
.bs-content .bs-content-2 .input-value .input-value-1 span {
  color: #121212;
  font-size: 14px;
  margin-left: 10px;
}

.bs-arrow-icon {
  color: #014779;
  font-size: 12px;
}
.bs-plus-icon {
  color: #529c00;
  font-size: 16px;
}

.bs-content .bs-content-6 .input-value {
  flex: 0 0 70%;
  width: 70%;
  max-width: 0 0 70%;
}

.bs-content .bs-content-6 .input-value table thead tr th {
  font-size: 14px;
  font-weight: 600;
}
.bs-content .bs-content-6 .input-value table tbody tr td {
  font-size: 14px;
}

@media only screen and (max-width: 470px) {
  .bs-content .bs-content-1 .label,
  .bs-content .bs-content-2 .label,
  .bs-content .bs-content-3 .label,
  .bs-content .bs-content-4 .label,
  .bs-content .bs-content-5 .label,
  .bs-content .bs-content-6 .label,
  .bs-content .bs-content-7 .label {
    flex: 0 0 40%;
    max-width: 0 0 40%;
    width: 40%;
  }
  .bs-content .bs-content-1 .input-value,
  .bs-content .bs-content-2 .input-value,
  .bs-content .bs-content-3 .input-value,
  .bs-content .bs-content-4 .input-value,
  .bs-content .bs-content-5 .input-value,
  .bs-content .bs-content-6 .input-value,
  .bs-content .bs-content-7 .input-value {
    flex: 0 0 60%;
    max-width: 0 0 60%;
    width: 60%;
  }
  .bs-content .bs-content-4 .input-value {
    flex-direction: column;
    align-items: flex-start;
  }
  .bs-content .bs-content-4 .input-value > * {
    margin-bottom: 10px;
  }
}
