* {
  margin: 0;
  padding: 0;
}

.summary {
  background-color: #f4f4f4;
}

.summary-header {
  margin: 0 20px;
}

/* .row {
  padding-top: 10px;
} */

.summary-grid-1 {
  padding: 0 20px;
}
.prog-sum .summary-grid-1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.prog-sum .summary-grid-1 .summary-grid-1-1,
.prog-sum .summary-grid-1 .summary-grid-1-2 {
  flex: 0 0 50%;
  max-width: 0 0 50%;
}
.summary-grid-1-1 {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.prog-sum .summary-grid-1 .summary-grid-1-2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.prog-sum .summary-grid-1 .summary-grid-1-2 .sum-publish-btn {
  margin-left: 10px;
  background-color: #ff8000;
  border: none;
}

.default-text {
  background-color: #800000;
  border: none;
}

.default-text:hover {
  background-color: #800000;
  border: none;
}

.prog-sum .summary-grid-1 .summary-grid-1-2 .sum-edit-btn,
.prog-sum .summary-grid-1 .summary-grid-1-2 .sum-delete-btn {
  margin-left: 10px;
  border: none;
}
.prog-sum .summary-grid-1 .summary-grid-1-2 .sum-edit-btn p,
.prog-sum .summary-grid-1 .summary-grid-1-2 .sum-delete-btn p,
.prog-sum .summary-grid-1 .summary-grid-1-2 .sum-publish-btn p {
  color: #ffffff;
  margin: 0;
}
/* .prog-sum .summary-grid-1 .summary-grid-1-2 .sum-edit-btn:hover,
.prog-sum .summary-grid-1 .summary-grid-1-2 .sum-delete-btn:hover,
.prog-sum .summary-grid-1 .summary-grid-1-2 .sum-publish-btn:hover {
  background-color: #0a7fd3;
} */

.summary-grid-1 h4 {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #121212;
}

.summary-grid-1 p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5a5a5c;
}

.vid {
  padding: 10px;
}

.vid .video {
  border-radius: 12px;
  /* min-height: 240px; */
  width: 100%;
}
.summary-grid-11 {
  padding: 20px;
}

/*----------------------------------------summary-grid-2----------------------------------------*/
.summary-grid-2 h5 {
  padding-top: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: #121212;
}

.summary-grid-2 p {
  font-family: Inter;
  font-style: normal;
  font-size: 1rem;
  color: #121212;
}

/*----------------------------------------------------summary-grid-3-----------------------------------------------------*/
/* .summary-grid-3 {
  margin-top: 40px;
} */

.summary-grid-3 .program-img img {
  width: 100%;
}

.summary-grid-3 h4 {
  padding-left: 20px;
  /* padding-top: 30px; */
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #121212;
  margin-bottom: 20px;
}

.summary-grid-3 p {
  padding-left: 20px;
  color: #800000;
}

.summary-grid-31 {
  margin: 20px;
}

/* #014779 */
.summary-grid-3-1 {
  margin-top: 16px;
  padding: 10px 10px;
  box-sizing: border-box;
  /* width: 451px; */
  height: 46px;
  background: #ffffff;
  box-shadow: 4px 4px 8px #00000018;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .summary-grid-3-1:hover {
  border: 2px solid #01477971;
} */

.summary-grid-3-1 h6 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #121212;
  margin: 0;
}

.summary-grid-3-1 p {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  color: #121212;
  margin: 0;
}

.summary-grid-3-2 {
  margin-top: 10px;
  padding: 10px 10px 0;
  box-sizing: border-box;
  width: 451px;
  height: 46px;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.summary-grid-3-2 h6 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #121212;
}

.summary-grid-3-2 p {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  color: #121212;
}

/* Start/continue program button */
.start-continue-program-btn {
  background-color: #146faf;
  width: fit-content;
  border: 1px solid #1f90e0;
  margin-right: 20px;
  padding: 1rem 1.5rem;
  border-radius: 3rem;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: #fff;
  transition: all 0.2s;
}
.start-continue-program-btn:hover {
  box-shadow: 1px 2px 3px #dbdbdb;
  background-color: #014e85;
  color: #fff;
}
.start-continue-program-btn > div {
  color: inherit;
  font-size: 1.1rem;
  font-weight: 600;
}
.start-continue-program-btn > svg {
  background-color: #529c00;
  height: 1.3rem;
  width: 1.3rem;
  padding: 5px 6px;
  border-radius: 50%;
}

.summary-arrow-icon {
  margin-right: 10px;
  background-color: #529c00;
  padding: 5px 6px;
  border-radius: 100px;
}

/* show program entry js styling */
.entry-conatiner {
  padding: 20px 20px 0;
}

/* .entry-conatiner .row {
  margin-top: 32px;
} */

.accordion-item .accordion-button {
  font-size: 22px;
  font-weight: 500;
}

.entry-conatiner .entry-left {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 20px;
}
.entry-conatiner .entry-right {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 20px;
}
.entry-conatiner .entry-left .entry-left-list {
  margin-top: 16px;
  padding: 10px 10px;
  box-sizing: border-box;
  height: 46px;
  background: #ffffff;
  box-shadow: 0px 0px 4px #00000018;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.entry-conatiner .entry-left .entry-left-list:hover {
  box-shadow: 0px 0px 8px #0a7fd380;
  border: none;
}

.entry-left-list .entry-title {
  flex-grow: 1;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.entry-conatiner .entry-left .entry-left-list .entry-icon-prgp {
  flex-shrink: 0;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.entry-conatiner
  .entry-left
  .entry-left-list
  .entry-icon-prgp
  .availability-label {
  margin-right: 10px;
  font-size: 0.8rem;
}

/* .entry-conatiner .entry-left .entry-left-list:hover {
  box-shadow: 1px 1px 12px #00000018;
} */

.entry-conatiner .entry-left .entry-left-list.active {
  background: #0980d4 !important;
  font-weight: 500;
  border: 1px solid #0980d4 !important;
  /* box-shadow: 0px 0px 4px #abd3ef; */
  color: #fff;
}

.entry-conatiner .entry-left .entry-left-list.is-additional {
  border: 2px solid #cbcbcb !important;
  /* border: none !important; */
}

.entry-conatiner .entry-left .entry-left-list .exercise-check-icon {
  padding: 6px 7px;
  background-color: #529c00;
  border-radius: 50%;
}
.entry-conatiner .entry-left .entry-left-list .exercise-arrow-icon {
  padding: 6px 7px;
  background-color: #0980d4;
  border-radius: 50%;
}

.entry-conatiner .entry-left .entry-left-list.active .exercise-check-icon,
.entry-conatiner .entry-left .entry-left-list.active .exercise-arrow-icon {
  background-color: #fff;
  color: #0980d4;
}

.not-available {
  border: 2px solid #529c00 !important;
  /* pointer-events: none; */
  opacity: 0.6;
}
.entry-conatiner .entry-right .entry-detail-answer {
  margin-top: 20px;
  padding: 18px 16px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 1px 4px #00000018;
  height: fit-content;
}

.entry-conatiner .entry-right .position-name .exercise-grid-3 {
  /* border: 1px solid black; */
  margin-bottom: 20px;
}
.entry-container .entry-right .position-name .exercise-grid-3 p {
  /* font-size: 2.5rem; */
  text-align: left;
  margin: 0;
}
.entry-conatiner .entry-right .position-name .vid {
  padding: 10px 0;
}

.entry-conatiner .entry-right .position-name .completed {
  margin-left: 28px;
  background: #014779;
  border: none;
}

.entry-conatiner .entry-right .position-name .vid .video {
  border-radius: 12px;
  /* min-height: 270px; */
  width: 100%;
}

.entry-conatiner .entry-right .position-name .form-check .form-check-input {
  margin-left: 0;
  margin-right: 10px;
}
.entry-conatiner .entry-right .position-name .exercise-question .form-select {
  margin-bottom: 20px;
}
.entry-conatiner .entry-right .position-name .exercise-btn .btn {
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  color: #121212;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 2px 8px #00000018;
}
.entry-conatiner
  .entry-right
  .position-name
  .exercise-btn
  .btn
  .exercise-plus-icon {
  background-color: #529c00;
  padding: 5px 6px;
  border-radius: 50%;
  border: none;
  margin-right: 10px;
  font-size: 12px;
}
.entry-conatiner .entry-right .position-name .exercise-grid-3-5 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 10px 0;
}
.entry-conatiner .entry-right .entry-ans-section .exercise-grid-3-5 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.complete-lesson-btn {
  margin: 0;
  background-color: #014779;
  width: fit-content;
  border-radius: 12px;
  padding: 10px 16px;
  color: #ffffff;
}

.entry-conatiner
  .entry-right
  .entry-ans-section
  .exercise-grid-3-5
  .complete-lesson-btn:hover {
  background-color: #0a7fd3;
}

/* .add-entry-btn {
  position: relative;
  margin-top: -76px;
}
.add-entry-btn .btn {
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  margin-left: 45%;
}
.add-entry-btn .btn:hover {
  box-shadow: 0 0 4px #014779;
}
.add-entry-btn .btn span {
  color: #121212;
  font-size: 14px;
}
.add-entry-btn .btn .exercise-plus-icon {
  margin-right: 10px;
  color: #529c00;
  font-size: 16px;
} */

/* .entry-conatiner .row .entry-right .entry-ans-section .exercise-question,
.entry-conatiner .row .entry-right .entry-ans-section .exercise-btn,
.entry-conatiner .row .entry-right .entry-ans-section .exercise-grid-3-5 {
  padding: 0 28px
} */

.entry-left .mb-4 p {
  font-size: 1.1rem;
  text-align: left; 
}
  
.accordion-body .position-name p{ 
  font-size: 1.1rem;
  text-align: left;
}

.entry-container .row .entry-right .entry-ans-section .exercise-question p {
  font-size: 1.1rem;
  text-align: left; 
}
.entry-conatiner
  .row
  .entry-right
  .entry-ans-section
  .exercise-question
  .form-select {
  margin-bottom: 10px;
}

.Hello {
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  background: white;
  margin: 0;
  padding: 8px 16px 8px 16px;
}

.Hello .me-2 {
  color: #800000;
}


.Hello .mb3 .me-2 {
  color: #800000;
}

.resource-container{
  display: block;
  gap: 20px;
}

.box-all{
  margin-bottom: 10p;
}

/* .entry-conatiner .row .entry-right .entry-ans-section .exercise-btn {
  border: 1px solid black;
  text-align: center;
  margin-bottom: 10px;
} */

/* Add entry button */
.add-entry-btn {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  padding: 1rem 1.2rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: #121212;
  display: inline-flex;
  align-items: center;
  gap: 1rem;
}
.add-entry-btn .exercise-plus-icon {
  color: #529c00;
  font-size: 1.4rem;
}

/* .add-entry-btn:hover {
  box-shadow: 0 0 4px #014779;
} */

.text-center .add-entry-btn:hover {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  color: #121212;
  box-shadow: 0 0 4px #0980d4 ;
}


.entry-conatiner
  .row
  .entry-right
  .entry-ans-section
  .exercise-btn
  .btn-danger {
  background: #fc1717;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
}

.entry-conatiner
  .row
  .entry-right
  .entry-ans-section
  .exercise-btn
  .btn-danger
  .exercise-trash-icon {
  color: #ffffff;
  font-size: 16px;
  margin-right: 10px;
}

.entry-conatiner
  .row
  .entry-right
  .entry-ans-section
  .exercise-btn
  .btn-danger
  .remove-entry {
  color: #ffffff;
  font-size: 14px;
}
.entry-conatiner .row .entry-right .entry-ans-section .completed {
  background: #014779;
  border: none;
  margin-bottom: 10px;
}
.entry-conatiner .row .entry-right .entry-ans-section .entry-video-answer {
  /* border: 1px solid black; */
  aspect-ratio: 16/3;
}
.entry-conatiner .row .entry-right .entry-ans-section .entry-video-answer div {
  /* border: 1px solid red; */
  width: 100% !important;
  height: auto !important;
  border-radius: 12px;
}
.entry-conatiner
  .row
  .entry-right
  .entry-ans-section
  .entry-video-answer
  div
  video {
  border-radius: 12px;
}

.prog-sum .summary-grid-11 nav .nav .nav-link {
  border: none;
  color: #014779 !important;
}

.prog-sum .summary-grid-11 nav .nav .nav-link:hover {
  border-bottom: 2px solid #014779;
  color: #014779 !important;
}

.prog-sum .summary-grid-11 nav .nav .nav-link.active {
  border-bottom: 2px solid #0a7fd3;
  color: #0a7fd3 !important;
}

.prompt-selection-modal .modal-header {
  background-color: #014779;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  padding: 1rem 1.6rem;
}
.prompt-selection-modal .modal-header .modal-title {
  color: #ffffff;
}
.prompt-selection-modal .modal-header .btn-close {
  background-color: #ffffff;
}

.prompt-selection-modal .modal-footer {
  background-color: #f9f9f9;
}
.prompt-selection-modal .modal-footer .continue-without-prompt {
  background-color: #014779;
  border: none;
  width: fit-content;
}

.additional-entry-btn {
  text-align: end;
}

.entry-right .position-name .box-all {
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
  box-shadow: 0px 0px 4px #00000018;
  background-color: white;
}

.entry-right .position-name .box-all p {
  margin: 0;
}

.entry-right .position-name .box-all .ptag {
  display: flex;
  flex-direction: row;
}
.entry-right .position-name .box-all .ptag a p {
  margin-left: 10px;
  color: black;
}
.entry-right .position-name .box-all .ptag a .full-width {
  display: inline-block;
}
.entry-right .position-name .box-all .ptag a .width-980 {
  display: none;
}
.entry-right .position-name .box-all .linktag .exercise-download-icon {
  color: #529c00;
  font-size: 22px;
}

.entry-right .position-name .box-all .linktag:hover .exercise-download-icon {
  box-shadow: 4px 4px 8px #00000018;
}

button {
  border: none;
  padding: 0;
  background: transparent;
}

.blink-text {
  /* position: absolute;
  top: 16%;
  left: 78%; */
  /* transform: translate(-50%, -50%); */
  color: #800000;
  font-size: 1.2em;
  font-family: serif;

  animation: animate 1.5s linear infinite;
  font-weight: bold;
}

@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 1300px) {
  .summary-grid-1 .summary-grid-1-1 h4 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 1250px) {
  .entry-conatiner .row .entry-left {
    padding-right: 10px;
  }
  .entry-conatiner .row .entry-right {
    padding-left: 10px;
  }
  .add-entry-btn .btn {
    margin-left: 44%;
  }
  .summary-grid-1 .summary-grid-1-1 h4 {
    font-size: 28px;
  }
  .summary-grid-3 h4 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 1200px) {
  .summary-grid-1 .summary-grid-1-1 h4 {
    font-size: 26px;
  }
  .entry-right .position-name .box-all .ptag a .full-width {
    display: none;
  }
  .entry-right .position-name .box-all .ptag a .width-980 {
    display: inline-block;
  }
}
@media only screen and (max-width: 1100px) {
  .entry-conatiner .row .entry-left,
  .entry-conatiner .row .entry-right {
    flex: 0 0 50%;
    max-width: 0 0 50%;
  }
  .add-entry-btn .btn {
    margin-left: 52%;
  }
}
@media only screen and (max-width: 980px) {
  .view-my-program .col {
    flex: 0 0 100%;
    max-width: 0 0 100%;
  }
  .entry-conatiner .row .entry-left,
  .entry-conatiner .row .entry-right {
    flex: 0 0 100%;
    max-width: 0 0 100%;
  }
  .prog-sum .col {
    flex: 0 0 100%;
    max-width: 0 0 100%;
  }
  /* .entry-conatiner .row .entry-right {
    margin-top: -67px;
  } */
  .entry-conatiner .entry-right .entry-detail-answer {
    margin-top: 40px;
  }
  .add-entry-btn {
    display: flex;
    justify-content: flex-end;
  }
  .add-entry-btn .btn {
    margin-left: 0;
  }
}

@media only screen and (max-width: 880px) {
  /* .prog-sum .summary-grid-1 {
    flex-direction: column;
    margin-bottom: 50px;
  } */
  .prog-sum .summary-grid-1 .summary-grid-1-1 {
    flex: 0 0 55%;
    max-width: 0 0 55%;
  }
  .prog-sum .summary-grid-1 .summary-grid-1-2 {
    flex: 0 0 45%;
    max-width: 0 0 45%;
  }



@media only screen and (max-width: 780px) {
  .add-entry-btn {
    display: block;
    margin: 0 auto; 
  }
}

  .entry-conatiner {
    padding: 30px 0;
  }

  .entry-conatiner .row {
    margin: 0;
  }
}

/* @media only screen and (min-width: 880px) {
  .entry-conatiner .row .entry-right {
    padding: 20px 30px;
  }
} */
