.imgBox {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 200px;
  width: 550px; */
}

.img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  transform: scale(1); 
  transform-origin: center;
}


.add-program4 {
  background: #f1f1f1;
  height: 100%;
}

.add-program4-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #014779;
  padding: 20px 40px;
}

.add-program4-header h5 {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #fff;
}

/*------------------------------------------grid 1--------------------------------------*/
.add-program4-grid {
  padding: 20px 20px;
}

.add-program4-grid-11{
  padding: 20px;
}

.add-program4-grid-11 h5 {
  font-size: 26px;
  font-weight: 700;
  margin: 0;
}

.add-program4-grid-11 p {
  font-size: 14px;
}


.add-program4-grid-1 {
  margin-top: 20px;
  padding: 0 20px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.add-program4-grid-1 .progrm-compltion-heading{
  display:flex;
  flex-direction: column;
}



.add-program4-grid-1 .add-program4-grid-1-1 h5 {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  margin: 0;
  color: #121212;
}
/* .add-program4-grid-1-1 {
  padding: 0 40px;
} */
.add-program4-grid-1 .add-program4-grid-1-1 p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  color: #424242;
}

.add-program4-form {
  margin-top: 10px !important;
  padding: 0 10px !important;
  width: 190% !important;
  background: #f8f9fa !important;
  border-radius: 8px !important;
}

.add-program4-grid-4-1,
.add-program4-grid-4-2,
.add-program4-grid-4-3,
.add-program4-grid-4-4,
.add-program4-grid-4-5,
.add-program4-grid-4-6 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;
}

.add-program4-grid-4-1 h6,
.add-program4-grid-4-2 h6,
.add-program4-grid-4-3 h6,
.add-program4-grid-4-4 h6,
.add-program4-grid-4-5 h6,
.add-program4-grid-4-6 h6 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #121212;
  width: 160px;
}

.add-program4-grid-4-1 p,
.add-program4-grid-4-2 p,
.add-program4-grid-4-3 p,
.add-program4-grid-4-4 p,
.add-program4-grid-4-5 p,
.add-program4-grid-4-6 p {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #121212;
  width: 160px;
}

.add-program4-grid-100 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.add-program4-grid-100 .form-check .form-check-input:checked {
  background-color: #529c00;
  border-color: #529c00;
}
.add-program4-grid-4-4 .add-program4-grid-4-41 {
  flex: 0 0 30%;
  max-width: 0 0 30%;
  margin-bottom: 10px;
}
.program-completion-form-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.program-completion-form-btn-1-1,
.program-completion-form-btn-2-1 {
  border: none;
  background: none;
  border-radius: 12px;
}
.program-completion-form-btn-2-1 {
  background: #014779;
}
.program-completion-form-btn-1-1 p,
.program-completion-form-btn-2-1 p {
  color: #121212;
  margin: 0;
}
.program-completion-form-btn-2-1 p {
  color: #ffffff;
}
.program-completion-form-btn-1-1:hover {
  background: #014779;
  border: none;
}
.program-completion-form-btn-2-1:hover {
  background: #0a7fd3;
  border: none;
}
.program-completion-form-btn-1-1:hover p,
.program-completion-form-btn-2-1:hover p {
  color: #ffffff;
}

@media only screen and (max-width: 1050px) {
  .add-program4-grid-4-1,
  .add-program4-grid-4-2,
  .add-program4-grid-4-3,
  .add-program4-grid-4-4,
  .add-program4-grid-4-5,
  .add-program4-grid-4-6 {
    flex-direction: column;
  }
  .add-program4-grid-4-1 .col,
  .add-program4-grid-4-2 .col,
  .add-program4-grid-4-3 .col,
  .add-program4-grid-4-4 .col,
  .add-program4-grid-4-5 .col,
  .add-program4-grid-4-6 .col {
    /* border: 1px solid #000; */
    flex: 0 0 100%;
    width: 100%;
    max-width: 0 0 100%;
    margin-bottom: 10px;
  }

  .add-program4-grid-4-1 h6,
  .add-program4-grid-4-2 h6,
  .add-program4-grid-4-3 h6,
  .add-program4-grid-4-4 h6,
  .add-program4-grid-4-5 h6,
  .add-program4-grid-4-6 h6 {
    width: 100%;
  }
  .add-program4-grid-4-1 p,
  .add-program4-grid-4-2 p,
  .add-program4-grid-4-3 p,
  .add-program4-grid-4-4 p,
  .add-program4-grid-4-5 p,
  .add-program4-grid-4-6 p {
    width: 100%;
  }

  .add-program4-grid-4-4 .add-program4-grid-4-41 {
    flex: 0 0 50%;
    max-width: 0 0 50%;
  }
}

@media only screen and (max-width: 710px) {
  .add-program4-grid-4-4 .add-program4-grid-4-41 {
    flex: 0 0 100%;
    max-width: 0 0 100%;
  }
}
@media only screen and (max-width: 950px) {
  .add-program4-grid-1 {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .add-program4-grid-1 .progress-container {
    width: 100%;
    margin-top: 12px;
  }
}