.user-onboarding {
  max-width: 440px;

  .step-icon-wrap {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;

    .step-icon {
      height: 90px;
      width: 90px;
      border-radius: 25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #0a7fd3;

      svg {
        height: 50px;
        width: 50px;
        color: #fff;
      }
    }

    .registered-icon {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      border: 4px solid #0a7fd333;
      svg {
        height: 61px;
        width: 79px;
        color: #0a7fd3;
      }
    }
  }

  .step-heading {
    font-family: "sf-pro";
    font-weight: 600;
    letter-spacing: 0.1rem;
    text-align: center;
    font-size: 41px;
    color: #153060;
    margin-bottom: 2rem;
  }

  .form-control {
    padding: 16px 12px 16px 12px;
    border-radius: 12px !important;
    border-color: transparent;
  }
  .btn {
    border-radius: 12px !important;
    padding: 18px 32px 18px 32px;
    font-size: 22px;
    line-height: 24px;
    width: 100%;
    font-family: "sf-pro";
    letter-spacing: 0.025rem;
  }
  .btn-primary {
    background-color: #0a7fd3;
  }

  .text-muted {
    color: #000000b2;
    font-family: "sf-pro";
    letter-spacing: 0.15px;
    font-size: 18px;
  }

  .resend-otp-btn {
    color: #f2451c;
    &:hover {
      text-decoration: underline;
    }
  }

  .form-check-label {
    font-family: "Inter";
    font-size: 14px;
    button {
      color: #4e8c09;
    }
  }
}
