.badge-uploader {
  display: flex;
  gap: 2rem;
  align-items: center;

  img {
    width: 7rem;
    height: 7rem;
    border: 1px solid #ccc;
    border-radius: 6px;
  }

  .upload-btn {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #fff;
    color: #529c00;
    border: 1px solid #529c00;
    border-radius: 6px;
    font-size: 0.9rem;
    font-weight: 500;
    &:hover {
      box-shadow: 1px 1px 0px hsla(88, 100%, 31%, 0.73);
    }
  }

  .remove-badge-btn {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #fff;
    color: #e52525;
    border: 1px solid #e52525;
    border-radius: 6px;
    font-size: 0.9rem;
    font-weight: 500;
    &:hover {
      box-shadow: 1px 1px 0px #e525259c;
    }
  }
}
