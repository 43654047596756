.library-grid {
  background-color: #f4f4f4;
}

.library-grid-1 {
  padding: 0 20px;
}

.lib-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lib-search-box {
  margin-right: 35px;
}

.lib-flex .lib-search-box .input-group-text {
  background-color: #f9f9f9;
}

.lib-flex .input-group select.form-select {
  min-width: 250px;
  margin-right: 30px;
  border-radius: 16px !important;
  color: #5a5a5c;
}

.rounded {
  border-radius: 10px !important;
}

.prog-lib-input-text {
  background: transparent !important;
  margin-left: 20px !important;
}

.library-grid-2 h4 {
  padding-top: 30px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  margin: 0;
  color: #121212;
}

.library-grid-2 p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  margin: 0;
  color: #5a5a5c;
}

/*--------------------------------------Grid 3---------------------------------------*/
.library-grid-3 {
  margin-top: 20px;
  padding: 0;
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  width: "100%";
}
.library-grid-3.darken img {
  filter: grayscale(0.5);
  transition: all 250ms;
}
.library-grid-3.darken.can-glow:hover img {
  filter: grayscale(0);
}
.card {
  height: 300px;
  overflow: hidden;
}
.card .program-type {
  position: absolute;
  top: 20px;
  right: 0px;
  z-index: 999;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.334);
}

.card .program-type .free {
  border: none;
  border-radius: 2px;
  background-color: orange;
  color: #fff;
  font-size: 12px;
  margin: 0;
  padding: 4px 20px;
  width: fit-content;
}

.card .program-type .paid {
  border: none;
  border-radius: 2px;
  background-color: #529c00;
  color: #fff;
  font-size: 12px;
  margin: 0;
  padding: 4px 20px;
  width: fit-content;
}


.library-grid-3 .imgBox {
  /* width: 100%;
  height: 140px;  */
  aspect-ratio: 16/9;
  position: relative;
  text-align: center;
  overflow: hidden;
}

.library-grid-3 .imgBox .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


@media (max-width: 1366px) {
  .library-grid-3 .imgBox {
    /* height: 180px; */
  }
}


@media (max-width: 1024px) {
  .library-grid-3 .imgBox .img {
    width: 100%;
    height: 100%;
    object-fit: inherit;
  }
}


@media (max-width: 768px) {
  .library-grid-3 .imgBox .img {
    width: 100%;
    height: 100%;
    object-fit: inherit;
  }
}


@media (max-width: 425px) {
  .library-grid-3 .imgBox .img {
    width: 100%;
  height: 100%;
     object-fit: inherit;
  }
}




.library-grid-3 .imgHover {
  width: 100%;
  height: 200px;
  position: absolute;
  overflow: hidden;
}
.library-grid-3 .cardBody {
  padding: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  min-height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.library-grid-3 .cardBodyHover {
  background-color: #fff;
  padding: 10px;
  width: 100%;
  position: absolute;
  bottom: 0;
  box-shadow: 0px -4px 6px rgba(255, 255, 255, 0.336);
  transition: all 0.3s ease-out;
  z-index: 111;
}
.library-grid-3 .cardBody-1 .title {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #344055;
  margin: 0;
}

.card .cardBody .title2{
  font-size: 12px;
  color: #222;
}
.library-grid-3 .cardBody-2 .desc {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  color: #79808a;
  margin: 0;
}

.library-grid-3 .cardBody-2 .desc button {
  border: none;
  background: none;
  margin-left: 4px;
}

.library-grid-3 h6 {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  text-align: right;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #424242;
}

.library-grid-3 h5 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #344055;
}

.library-grid-3 p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  display: flex;
  align-items: flex-end;
  color: #79808a;
}

.library-grid-3-1 {
  /* margin-top: 20px; */
  display: flex;
  align-items: center;
}

.library-grid-3-1 h5 {
  padding-top: 6px;
  padding-left: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  color: #79808a;
  margin: 0;
}

.library-grid-3-1 .btn {
  background: none;
  border: none;
}

.library-grid-3-1 .btn:hover {
  background: none;
}

.lib-arrow-icon {
  margin-left: 20px;
  background-color: #529c00;
  display: inline-block;
  padding: 8px 9px;
  border-radius: 100px;
}

.serch-icon {
  background: transparent;
  border: none;
}

/*-----------------------------------pop up----------------------------*/
.library-wrapper {
  background: #424242;
  opacity: 0.9;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 111;
}

.library-container {
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.5rem;
  min-width: 450px;
  z-index: 111;
}
.library-container .container-fluid form {
  width: 100%;
}
.library-container .form-control {
  background-color: #ffffff;
  border: none;
}
.library-container .input-group-text {
  background-color: #fff;
  font-size: 18px;
}
.library-container .input-group-text .search-icon {
  color: #121212;
}

@media only screen and (min-width: 820px) and (max-width: 1070px) {
  .prog_card .col {
    flex: 0 0 50%;
    max-width: 0 0 50%;
  }
}
@media only screen and (max-width: 920px) {
  .library-grid-2 h4 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 820px) {
  .prog_card .col {
    flex: 0 0 100%;
    max-width: 0 0 100%;
  }
  .library-grid-2 h4 {
    font-size: 28px;
  }
}
@media only screen and (max-width: 720px) {
  .library-grid-2 h4 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 450px) {
  .library-container {
    min-width: 0;
    width: 80%;
  }
}
