@import "./mixins.scss";
@import "./buttons.scss";

$red: #f33f3f;
$green: #529c00;

@include color-styles("red", $red);
@include color-styles("green", $green);

.panel__white {
    background-color: #fff;
    padding: 1rem;
    border: 1px solid #e9ecef;
    border-radius: 6px;
}