.main-div {
    background: #f1f1f1;
  }
  
  .add-program {
    background: #f1f1f1;
    height: 100%;
  }
  
  .add-program-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #014779;
    padding: 20px 40px;
  }
  
  .add-program-header h5 {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #fff;
  }
  
  /*------------------------------------------grid 1--------------------------------------*/
  .add-program-grid {
    padding: 20px 40px;
  }
  .add-program-grid-1 {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .add-program-grid-1 .add-program-grid-1-1 h5 {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    color: #121212;
    margin: 0;
  }
  
  .add-program-grid-1 .add-program-grid-1-1 p {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #424242;
    margin: 0;
  }
  
  /*-------------------------------------grid 2--------------------------------*/
  .add-program-grid-2 {
    margin-top: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .add-program-grid-2-1 {
    /* width: 457px;
      height: 283px; */
    padding: 20px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 12px;
    box-sizing: border-box;
    min-height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .add-program-grid-2-1 h5 {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #121212;
  }
  
  .add-program-grid-2-1 h6 {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #121212;
    line-height: 0px;
  }
  
  .add-program-grid-2-1 p {
    width: 250px;
    padding-top: 10px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #121212;
  }
  
  .add-program-grid-2-2 {
    margin-left: 30px;
    /* width: 457px;
      height: 283px; */
    padding: 20px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 12px;
    box-sizing: border-box;
    min-height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .add-program-grid-2-2 h5 {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #121212;
  }
  
  .add-program-grid-2-2 h6 {
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #121212;
    line-height: 0px;
  }
  
  .add-program-grid-2-2 p {
    width: 250px;
    padding-top: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #121212;
  }
  
  .add-program-grid-2-3 {
    padding: 5px 20px;
    background: #014779;
    border-radius: 8px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
    border: none;
  }
  .add-program-grid-2-3:hover {
    background: #0a7fd3;
  }
  .add-program-grid-2-4 {
    margin-top: 170px;
    background: transparent;
    border: none;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #5a5a5c;
  }
  
  @media only screen and (max-width: 640px) {
    .add-program-grid-1 {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .add-program-grid-1 .progress-container {
      width: 100%;
      margin-top: 10px;
    }
    .add-program-grid-2 {
      flex-direction: column;
      margin-top: 50px;
    }
    .add-program-grid-2 .add-program-grid-2-2,
    .add-program-grid-2 .add-program-grid-2-1 {
      width: 100%;
    }
    .add-program-grid-2 .add-program-grid-2-2 {
      margin-left: 0;
      margin-top: 12px;
    }
    .add-program-grid-2 .add-program-grid-2-2 p,
    .add-program-grid-2 .add-program-grid-2-1 p {
      width: 100%;
    }
    .add-program-grid-2-4 {
      margin-top: 50px;
    }
  }