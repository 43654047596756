.add-program3 {
  background: #f1f1f1;
  height: 100%;
}

.add-program3-grid {
  /* background: #f1f1f1; */
}

.add-program3-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #014779;
  padding: 20px 40px;
}

.add-program3-header h5 {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #fff;
}

/*------------------------------------------grid 1--------------------------------------*/
.add-program3-grid {
  padding: 20px 40px;
}
.add-program3-grid-1 {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.add-program3-grid-1 .add-program3-grid-1-1 h5 {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  margin: 0;
  color: #121212;
}

.add-program3-grid-1 .add-program3-grid-1-1 p {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  margin: 0;
  color: #424242;
}

.add-program3-form {
  width: 100% !important;
  background: #f8f9fa !important;
  border-radius: 8px !important;
}

.add-program3-grid-1-4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-program3-grid-1-4 .add-program3-grid-1-5 h6 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #121212;
}

/*-------------------------------------grid 2--------------------------------*/

.add-program3-niraj-2-2 {
  padding: 0 20px;
  display: flex;
  align-items: center;
  background: #ffffff !important;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
}

.niraj > input {
  display: none;
}

.add-program3-grid-1-5 {
  /* border: 1px solid #000; */
  position: absolute;
  right: 40px;
  top: 295px;
}
.add-program3-grid-1-5 .nav {
  /* border: 1px solid #000; */
  width: 220px;
}
.add-program3-grid-1-5 .nav .list-group {
  width: 100%;
  /* border: 1px solid #000; */
}
.add-program3-grid-1-5 .nav .list-group .list-group-item {
  /* border: 1px solid #000; */
  border-radius: 12px;
  margin-bottom: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.158);
}
.add-program3-grid-1-5 .nav .list-group .list-group-item a {
  color: #121212;
  font-size: 16px;
}
/* .add-program3-grid-4 .filepond--wrapper {
  width: 60%;
} */

/* .edit-entry-page section,
.edit-prompts-page section {
  padding: 0 0px;
} */
.edit-entry-page .edit-btn,
.edit-entry-page .delete-btn,
.edit-prompts-page .edit-btn,
.edit-prompts-page .delete-btn {
  border: 1px solid #e2e8f0;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
}
.edit-entry-page .edit-btn span,
.edit-prompts-page .edit-btn span {
  color: #529c00;
  font-size: 16px;
  margin-right: 10px;
}
.edit-entry-page .edit-btn .edit-icon,
.edit-prompts-page .edit-btn .edit-icon {
  border: none;
  border-radius: 50%;
  color: #ffffff;
  background-color: #529c00;
  font-size: 10px;
  padding: 6px;
}
.edit-entry-page .delete-btn span,
.edit-prompts-page .delete-btn span {
  color: #bb2d3b;
  font-size: 16px;
  margin-right: 10px;
}
.edit-entry-page .delete-btn .delete-icon,
.edit-prompts-page .delete-btn .delete-icon {
  border: none;
  border-radius: 50%;
  color: #ffffff;
  background-color: #bb2d3b;
  font-size: 10px;
  padding: 6px;
}
.edit-entry-page .add-new-btn,
.edit-prompts-page .add-new-btn {
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  margin-left: 1%;
}

.edit-entry-page .add-new-btn:hover,
.edit-prompts-page .add-new-btn:hover{
  box-shadow: 0 0 4px #014779;
}

.edit-entry-page .add-new-btn span,
.edit-prompts-page .add-new-btn span{
  color: #121212;
  font-size: 14px;
}




.edit-entry-page .add-new-btn .entries-btnicon,
.edit-prompts-page .add-new-btn .prompt-btnicon{
  color: #529c00;
  font-size: 18px;
  margin-right: 10px;
}




.entry-form-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.entry-form-btn-1,
.entry-form-btn-2 {
  display: flex;
  flex-direction: row;
}

.entry-form-btn-1-1,
.entry-form-btn-1-2,
.entry-form-btn-2-1,
.entry-form-btn-2-2 {
  border: none;
  border-radius: 12px;
  background: none;
}

.entry-form-btn-1-1 p,
.entry-form-btn-1-2 p,
.entry-form-btn-2-1 p,
.entry-form-btn-2-2 p {
  color: #121212;
  font-size: 14px;
  margin: 0;
}

.entry-form-btn-1-1:hover p,
.entry-form-btn-1-2:hover p,
.entry-form-btn-2-2:hover p {
  color: #fff;
}
.entry-form-btn-2-1:hover p {
  color: #121212;
}
.entry-form-btn-2-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border: 1px solid #e2e8f0;
}

.entry-form-btn-2-1 .add-button {
  color: #529c00;
  margin-right: 10px;
}

/* .entry-form-btn-2-1:hover .add-button {
  color: #fff;
} */

.entry-form-btn-1-1 {
  margin-right: 10px;
}

.entry-form-btn-2-2 {
  margin-left: 10px;
  background: #014779;
  border: 1px solid #014779;
}

.entry-form-btn-2-2 p {
  color: #fff;
}

.entry-form-btn-1-1:hover,
.entry-form-btn-1-2:hover {
  background: #014779;
  border: none;
}

.entry-form-btn-2-1:hover {
  box-shadow: 0px 0px 4px #014779;
  background: #ffffff;
}
.entry-form-btn-2-2:hover {
  background: #0a7fd3;
  border: 1px solid #0a7fd3;
}

.add-program3-grid-100 {
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.add-program3-grid-3-6 .add-program3-grid-100,
.add-program3-grid-3-9 .add-program3-grid-100 {
  width: 100%;
}
.add-program3-grid-100 .form-check .form-check-input:checked {
  background-color: #529c00;
  border-color: #529c00;
}
.add-program3-grid-3-51,
.add-program3-grid-3-61,
.add-program3-grid-3-71,
.add-program3-grid-3-81,
.add-program3-grid-3-91 {
  flex: 0 0 33.33%;
  max-width: 0 0 33.33%;
  margin-bottom: 10px;
}
.add-program3-grid-3-1,
.add-program3-grid-3-2,
.add-program3-grid-3-3,
.add-program3-grid-3-4,
.add-program3-grid-3-5,
.add-program3-grid-3-6,
.add-program3-grid-3-7,
.add-program3-grid-3-8,
.add-program3-grid-3-9 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;
}

.add-program3-grid-3-1 {
  margin-top: 30px;
}

.add-program3-grid-3-1 h6,
.add-program3-grid-3-2 h6,
.add-program3-grid-3-3 h6,
.add-program3-grid-3-4 h6,
.add-program3-grid-3-5 h6,
.add-program3-grid-3-6 h6,
.add-program3-grid-3-7 h6,
.add-program3-grid-3-8 h6,
.add-program3-grid-3-9 h6 {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #121212;
  width: 160px;
  margin: 0;
}

.add-program3-grid-3-1 p,
.add-program3-grid-3-2 p,
.add-program3-grid-3-3 p,
.add-program3-grid-3-4 p,
.add-program3-grid-3-5 p,
.add-program3-grid-3-6 p,
.add-program3-grid-3-7 p,
.add-program3-grid-3-8 p,
.add-program3-grid-3-9 p {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #121212;
  width: 160px;
}

.add-program3-grid-3-6 .select-prompt-question {
  /* width: 100%; */
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: flex-end; */
}
.select-prompt-question .add-prompt-btn {
  font-size: .8rem;
  color: darkblue;
  text-decoration: underline;
}
.add-program3-grid-3-6 .select-prompt-question .form-select {
  width: 30%;
}

.add-program3-grid-3-9 .timed-access {
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.timed-access div .form-control {
  margin-right: 20px;
}

@media only screen and (max-width: 1050px) {
  .add-program3-grid-3-1,
  .add-program3-grid-3-2,
  .add-program3-grid-3-3,
  .add-program3-grid-3-4,
  .add-program3-grid-3-5,
  .add-program3-grid-3-6,
  .add-program3-grid-3-7,
  .add-program3-grid-3-8,
  .add-program3-grid-3-9 {
    flex-direction: column;
  }
  .add-program3-grid-3-1 .col,
  .add-program3-grid-3-2 .col,
  .add-program3-grid-3-3 .col,
  .add-program3-grid-3-4 .col,
  .add-program3-grid-3-5 .col,
  .add-program3-grid-3-6 .col,
  .add-program3-grid-3-7 .col,
  .add-program3-grid-3-8 .col,
  .add-program3-grid-3-9 .col {
    flex: 0 0 100%;
    width: 100%;
    max-width: 0 0 100%;
    margin-bottom: 10px;
  }

  .add-program3-grid-3-1 h6,
  .add-program3-grid-3-2 h6,
  .add-program3-grid-3-3 h6,
  .add-program3-grid-3-4 h6,
  .add-program3-grid-3-5 h6,
  .add-program3-grid-3-6 h6,
  .add-program3-grid-3-7 h6,
  .add-program3-grid-3-8 h6,
  .add-program3-grid-3-9 h6 {
    width: 100%;
  }
  .add-program3-grid-3-1 p,
  .add-program3-grid-3-2 p,
  .add-program3-grid-3-3 p,
  .add-program3-grid-3-4 p,
  .add-program3-grid-3-5 p,
  .add-program3-grid-3-6 p,
  .add-program3-grid-3-7 p,
  .add-program3-grid-3-8 p,
  .add-program3-grid-3-9 p {
    width: 100%;
  }

  .add-program3-grid-100 .add-program3-grid-3-51,
  .add-program3-grid-100 .add-program3-grid-3-61,
  .add-program3-grid-100 .add-program3-grid-3-71,
  .add-program3-grid-100 .add-program3-grid-3-81,
  .add-program3-grid-100 .add-program3-grid-3-91 {
    flex: 0 0 50%;
    max-width: 0 0 50%;
  }
  .add-program3-grid-3-6 .select-prompt-question {
    justify-content: flex-start;
    margin-left: 10px;
  }
  .add-program3-grid-3-9 .timed-access {
    width: 100%;
  }
}

@media only screen and (max-width: 850px) {
  .entry-form-btn-1,
  .entry-form-btn-2 {
    flex-direction: column-reverse;
  }
  .entry-form-btn-1-2,
  .entry-form-btn-2-2 {
    margin-bottom: 10px;
  }
  .add-program3-grid-3-6 .select-prompt-question .form-select {
    width: 50%;
  }
}
@media only screen and (max-width: 770px) {
  .add-program3-grid-100 .add-program3-grid-3-51,
  .add-program3-grid-100 .add-program3-grid-3-61,
  .add-program3-grid-100 .add-program3-grid-3-71,
  .add-program3-grid-100 .add-program3-grid-3-81,
  .add-program3-grid-100 .add-program3-grid-3-91 {
    flex: 0 0 100%;
    max-width: 0 0 100%;
  }
  .add-program3-grid-3-6 .select-prompt-question .form-select {
    width: 50%;
  }
  .add-program3-grid-3-9 .timed-access {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 1228px) {
  .add-program3-grid-1-4 {
    /* border: 1px solid black; */
  }
  .add-program3-grid-1-5 {
    position: relative;
    top: 30px;
    left: 0;
    margin-bottom: 30px;
    width: 100%;
    /* border: 1px solid black; */
  }
  .add-program3-grid-1-5 .nav {
    /* border: 1px solid #000; */
    width: 100%;
  }
  .add-program3-grid-1-5 .nav .list-group .list-group-item {
    width: 75%;
  }
}

@media only screen and (max-width: 1050px) {
  .add-program3-grid-1-5 .nav .list-group .list-group-item {
    width: 100%;
  }
}
@media only screen and (max-width: 960px) {
  .add-program3-grid {
    padding: 0 12px;
  }
  .add-program3-grid-1 {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .add-program3-grid-1 .progress-container {
    width: 100%;
    margin-top: 12px;
  }
}