.sidebar__wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: flex-start; */
  padding: 30px 15px;
  isolation: isolate;
}

.sidebar__encap__logo {
  padding-left: 20px;
  margin-bottom: 10px;
}
.sidebar__encap__logo > img {
  height: 72px;
}

.sidebar__content__area {
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.sidebar__link {
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  /* gap: 17px;/ */
  text-decoration: none;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #5a5a5c;
  transition: 250ms all ease-out;
}

.sidebar__title {
  border-radius: 7px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  /* color: #014779; */
  color: #939393;
  transition: 250ms all ease-out;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-left: 20px;
}

.sidebar__title p {
  margin: 0;
}

.sidebar__icon {
  width: 20px;
  height: 20px;
  margin-right: 15px;
  color: #5a5a5c;
}

.sidebar__link:hover {
  color: #5a5a5c;
}

.sidebar__link.active {
  background-color: #014779;
  color: #fff;
}
.sidebar__link.active .sidebar__icon {
  color: #fff;
}

.sidebar__separator {
  /* border-bottom: 2px solid #79808a; */
  margin: 10px 0;
}

.sidebar__content__area .header-login-logout {
  display: none;
}
@media only screen and (max-width: 650px) {
  .sidebar__content__area .header-login-logout {
    display: inline-block;
  }
}

.sidebar__link .notification-counter-badge {
  margin-left: auto;
  background-color: rgb(209, 17, 65);
  color: #fff;
  font-size: 0.8rem;
  font-family: "Inter";
  border-radius: 2.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
}
