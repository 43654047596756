@import "./mixins.scss";

.header-badges {
  display: none;
  @include md {
    display: inline-flex;
  }
  img,
  .plus-badges-count {
    width: 2rem;
    height: 2rem;
    background-color: #fff;
    padding: 2px;
    border-radius: 2.5rem;
    border: 1.5px solid #dadada;
    margin-left: -0.4rem;
  }
  .plus-badges-count {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
  }
}
