.entry-form-modal .modal-header {
    padding: 20px 60px;
    background: #014779;
  }

  .entry-form-modal .modal-header .modal-title{
    color:#fff ;
   }

  .entry-form-modal .modal-body {
    padding: 20px 60px;
    border-radius: 0;
  }


  .entry-modal  .btn{
    border: none;
    border-radius: 12px;
    background: none;
}

 .entry-modal .close-btn{
  color: #fff;
  background-color: #bb2d3b;
}
.entry-modal .save-btn{
   color: #fff;
   background: #014779;
}

