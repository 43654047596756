@import "./mixins.scss";

.dash-header {
  background-color: #fbfbfb;
  padding: 15px 0;
  border-bottom: 1px solid #d9d9d9;
  color: #424242;
  @include sm {
    padding: 15px 20px;
  }

  .greetings {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 0;
  }

  .dash-header-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-right {
    display: flex;
    align-items: center;
  }
}
// TODO::verify its usage
.profile-pic {
  height: 40px;
  width: 40px;
  border-radius: 25px;
}

// TODO::check this with login modal
/*-----------------------------------pop up----------------------------*/
.login-main {
  background-color: rgba(125, 132, 137, 0.71);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.login-popup {
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.5rem;
}

/* HEADER ICON */
.header__icon {
  color: inherit;
  font-size: 1.2rem;
  &:hover {
    color: #3e7eac;
  }
}
.header__icon.bell__box {
  position: relative;
  .counter__badge {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: red;
    height: 10px;
    width: 10px;
    border-radius: 50%;
  }
}

.user-options-dropdown {
  .dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
    color: inherit;
    display: flex;
    align-items: center;
    gap: 10px;

    .name {
      display: inline-block;
      width: 12ch;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .dropdown-menu {
    min-width: 300px;
    > * {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

@media only screen and (max-width: 650px) {
  .dash-header {
    padding-left: 60px;
  }
  .greetings.authenticated {
    display: none;
  }
}
