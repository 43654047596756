.management-container {
  background-color: #f9f9f9;
}
.management-container .row {
  margin: 0;
}
.management-container p {
  margin: 0;
  font-family: Inter;
  font-style: normal;
  color: #121212;
}

.button {
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 0px 4px #00000018;
  padding: 4px 12px;
}
/* .management-container .left,
  .management-container .right {
    padding: 20px;
  } */
.management-container .head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.management-container .head .head-right .btn {
  margin-left: 10px;
}
.management-container .head .head-left,
.management-container .subhead,
.management-container .left .left-1 {
  margin-bottom: 16px;
}
.management-container .head .head-left .head-text-1 {
  font-weight: 700;
  font-size: 26px;
}
.management-container .head .head-left .head-text-2,
.management-container .left .left-1 .sub-text .sub-text-2,
.management-container .right .right-1 .sub-text .sub-text-2 {
  font-size: 14px;
  color: #79808a;
}

.management-container .subhead-left .button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.management-container .subhead-left .button .plus-icon {
  color: #529c00;
  margin-right: 10px;
}

.management-container .subhead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.management-container .subhead-right {
  display: flex;
  flex-direction: row;
}
.management-container .subhead-right .subhead-righttext {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-left: 20px;
}

.management-container .subhead-right .subhead-righttext .right-text-1 {
  color: #529c00;
  font-size: 26px;
  font-weight: 700;
}
.management-container .subhead-right .subhead-righttext .right-text-2 {
  color: #a3baca;
  font-size: 20px;
  margin-left: 10px;
}

.management-container .right .right-1 .sub-text .sub-text-1,
.management-container .left .left-1 .sub-text .sub-text-1 {
  font-weight: 700;
  font-size: 20px;
}
.management-container .left .left-1 .left-list {
  padding: 0;
  margin: 0;
}
.management-container .left .left-1 .left-list .button {
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 0px 4px #00000018;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 12px;
  margin-top: 10px;
}
.management-container .left .left-1 .left-list .button .left-list-text {
  display: flex;
  flex-direction: row;
}
.management-container .left .left-1 .left-list .button .arrow-right-icon {
  color: #529c00;
}

.management-container .right .right-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
}

.management-container .right .right-1 .nav .nav-item .nav-link {
  border: none;
  color: #014779 !important;
  padding: 6px 10px;
}
.management-container .right .right-1 .nav .nav-item .nav-link:hover {
  border-bottom: 2px solid #014779;
  color: #014779 !important;
}
.management-container .right .right-1 .nav .nav-item .nav-link.active {
  border-bottom: 2px solid #0a7fd3;
  color: #0a7fd3 !important;
}
.management-container .right .right-1 .search-box {
  /* padding: 10px; */
  /* margin-right: 10px; */
}

.management-container .right .right-2 .card {
  border: 1px solid #e2e8f0;
  margin-bottom: 20px;
  min-height: 0 !important;
}
.management-container .right .right-2 .card .card-body {
  padding: 16px 20px;
}
.management-container .right .right-2 .card .card-body .card-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.management-container .right .right-2 .card .card-body .card-btn {
  border: none;
  background: none;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.management-container .right .right-2 .card .card-body .card-btn p {
  font-size: 12px;
  color: #79808a;
}
.management-container
  .right
  .right-2
  .card
  .card-body
  .card-btn
  .arrow-right-icon {
  color: #529c00;
  font-size: 16px;
  margin-left: 10px;
}

.management-container .right .right-3 .button {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.management-container .right .right-3 .button .text {
  text-align: left;
}
.management-container .right .right-3 .button .text .text-1 {
  font-size: 22px;
  font-weight: 500;
}
.management-container .right .right-3 .button .text .text-2 {
  font-size: 10px;
  color: #79808a;
}
.management-container .right .right-3 .button .plus-icon {
  font-size: 30px;
  margin-left: 20px;
  color: #529c00;
}


