/* TODO::This is not applied */
.card-element {
  base::placeholder {
    color: #aab7c4;
  }

  base::focus {
    color: #424770;
  }

  base {
    font-size: 16px;
    color: #424770;
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 10px;
  }

  invalid {
    color: #9e2146;
  }
}

.stripe-input .StripeElement {
  padding: 0.75rem;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
  display: block;
  width: 100%;
}

.stripe-input .StripeElement--invalid {
  border-color: #dc2727;
}

.stripe-input .StripeElement--focus {
  outline: 0;
  border-color: hsla(210, 96%, 45%, 50%);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

